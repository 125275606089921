import React, { memo } from 'react'
import { View, StyleSheet } from 'react-native'

import { gray } from '../utils/colors'

import LogoWithTextInline from '../images/logo-with-text-inline.svg'
import LogoSolo from '../images/LogoSoloLight.svg'

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    height: 64
  }
})

interface LogoProps {
  fill?: string
  width?: number
  isCompact?: boolean
}

function Logo(props: LogoProps): JSX.Element {
  const isCompact = props.isCompact ?? true
  const iconColor =
    props.fill !== undefined ? props.fill : gray['700'].contrastText.toString()
  const width = props.width !== undefined ? props.width : isCompact ? 48 : 120
  const LogoSvg = isCompact ? LogoSolo : LogoWithTextInline

  return (
    <View style={styles.wrapper}>
      <LogoSvg width={width} color={iconColor} />
    </View>
  )
}

export default memo(Logo)
