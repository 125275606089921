import React from 'react'

import Button, { ButtonProps } from '../components/Button'

function ButtonContainer(props: ButtonProps): JSX.Element {
  // TODO
  const isTouchInput = false
  return <Button {...props} isTouchInput={isTouchInput} />
}

export default React.memo(ButtonContainer)
