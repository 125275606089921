import React, { useCallback, useRef, useMemo } from 'react'
import { ScrollView, LayoutRectangle } from 'react-native'

import { KeyboardLayoutStyle } from 'midi-city-shared-types'
import styles from './Keyboard/styles'

import Surface from './Surface'
import { KeyRenderLabelProps as KeyRenderLabelPropsT } from './Keyboard/Key'
import ScrollViewCustom from './ScrollViewCustom'
import { KeyboardMachineActor } from 'midi-city-app-manager/src/keyboard-machine'
import KeysWrapper from './KeysWrapper'

export interface KeyboardOwnProps {
  keyboardMachine: KeyboardMachineActor
  onKeysVisibleChange?: (keys: number[]) => void
  renderKeyLabel: (args: KeyRenderLabelPropsT) => JSX.Element
  snapToKeys?: number[]
  color: string
  isFocused: boolean
}

interface KeyboardProps extends KeyboardOwnProps {
  isTouch: boolean
  keyboardLayout: KeyboardLayoutStyle
  trackbarLayout: LayoutRectangle
  keysWrapperLayout: LayoutRectangle
  keysContentLayout: LayoutRectangle
  dimensions: LayoutRectangle
  onScroll: (x: number) => void
  scrollX: number
  scrollXInitial: number
}

function Keyboard(props: KeyboardProps): JSX.Element {
  const {
    keysWrapperLayout,
    keysContentLayout,
    isTouch,
    dimensions,
    trackbarLayout,
    isFocused,
    onScroll,
    scrollXInitial,
    renderKeyLabel,
    color
  } = props

  const contentOffset = useMemo(() => {
    return { x: scrollXInitial, y: 0 }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollXInitial])

  const scrollView = useRef<ScrollView | null>(null)

  const handleScroll = useCallback(
    (event): void => {
      const x = event.nativeEvent.contentOffset.x
      onScroll(x)
    },
    [onScroll]
  )

  const snapToPoints: number[] = []

  const { width, height } = dimensions
  const display = height > 0 ? 'flex' : 'none'

  return (
    <Surface
      testID="Keyboard"
      depth={1}
      style={[styles.container, { width, height, display }]}
      dataSet={{ 'web-user-select-none': '' }}>
      <ScrollViewCustom
        isFocused={isFocused}
        contentOffset={contentOffset}
        scrollViewDimensions={keysWrapperLayout}
        contentSize={keysContentLayout}
        trackbarLayout={trackbarLayout}
        ref={scrollView}
        style={styles.scrollContainer}
        onScroll={handleScroll}
        testID="Keyboard-ScrollView"
        snapToPoints={snapToPoints}
        isTouch={isTouch}>
        <KeysWrapper color={color} renderKeyLabel={renderKeyLabel} />
      </ScrollViewCustom>
    </Surface>
  )
}

export type KeyRenderLabelProps = KeyRenderLabelPropsT
export default React.memo(Keyboard)
