import { useMemo } from 'react'
import { GroupItem } from '../components/PickerList'

export function useLongestItemLabel<T>(items: Array<GroupItem<T>>): string {
  return useMemo(
    () =>
      items.reduce(
        (longestLabel, item): string =>
          item.label.length > longestLabel.length ? item.label : longestLabel,
        ''
      ),
    [items]
  )
}
export function useCenterButtonWidth<T>(
  items: Array<GroupItem<T>>,
  isCompact?: boolean
): number {
  const longestItemLabel = useLongestItemLabel(items)
  return useMemo(() => {
    if (isCompact === true) {
      return 82
    }
    const max = 240
    const iconWidth = 48
    const natural = longestItemLabel.length * 8 + iconWidth * 2
    return natural > max ? max : natural
  }, [isCompact, longestItemLabel])
}
