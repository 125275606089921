import React, { ReactNode } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import stylesText from '../styles/text'
import Surface from './Surface'
import { TypedDocumentNode } from 'midi-city-sanity'
import { assertIsObject, assertIsString } from 'assertate'
import imageUrlBuilder from '../utils/image-url-builder'

const MAX_HEIGHT = 160
interface BannerAdProps {
  ad: TypedDocumentNode.AdsQuery['allAd'][0]
}

const styles = StyleSheet.create({
  wrapper: {
    padding: 16,
    margin: 8,
    flexDirection: 'column',
    flex: 1,
    maxHeight: MAX_HEIGHT,
    opacity: 0.5
  },

  wrapperHovered: {
    opacity: 1.0
  },
  sponsoredByText: {
    color: 'gray',
    fontStyle: 'italic'
  }
})

const sponsoredByTextStyle = [stylesText.caption, styles.sponsoredByText]

function BannerAd({ ad }: BannerAdProps): JSX.Element {
  const { url, text, subText, image } = ad
  assertIsString(url)
  assertIsString(text)
  assertIsObject(image)

  const { asset } = image
  assertIsObject(asset)

  const imageUrl = imageUrlBuilder
    .image(asset._id as string)
    .height(MAX_HEIGHT)
    .url()

  assertIsString(imageUrl)

  const imageWidth = asset?.metadata?.dimensions?.width ?? ''
  const imageHeight = asset?.metadata?.dimensions?.height ?? ''

  return (
    <Pressable style={{ alignSelf: 'stretch' }}>
      {({ hovered }: { hovered: boolean }): ReactNode => (
        <Surface
          depth={1}
          style={[styles.wrapper, hovered ? styles.wrapperHovered : null]}>
          <View style={{ flex: 1 }} accessibilityRole="link" href={url}>
            <View style={{ flexShrink: 1 }}>
              <img
                src={imageUrl}
                width={imageWidth}
                height={imageHeight}
                style={{ height: '100%', width: 'auto', objectFit: 'contain' }}
              />
            </View>
            <View style={{ flexGrow: 0, flexShrink: 0, paddingTop: 8 }}>
              <Text style={stylesText.caption}>{text}</Text>
              <Text style={sponsoredByTextStyle}>{subText}</Text>
            </View>
          </View>
        </Surface>
      )}
    </Pressable>
  )
}

export default React.memo(BannerAd)
