import React, { useCallback, useMemo } from 'react'

import { Text } from 'react-native'
import styles from '../styles/text'
import RadioGroup from './RadioGroup'

import { globalSettingsColor } from '../utils/colors'
import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import { getInputSelected } from 'midi-city-app-manager/src/selectors/midi-device-manager'
import { usePartial } from 'midi-city-ui'

interface MidiInputSelectorProps {
  deviceManager: MidiDeviceManagerInterpreter
}

function MidiInputSelector({
  deviceManager
}: MidiInputSelectorProps): JSX.Element {
  const inputsAvailable = usePartial(
    deviceManager,
    ({ context }) => context.midiInputs
  )
  const inputSelected = usePartial(deviceManager, ({ context }) =>
    getInputSelected(context)
  )

  const inputIdSelected = inputSelected?.id

  const items = useMemo<Array<[string, string]>>(
    () => inputsAvailable.map(({ id, name }) => [name, id]),
    [inputsAvailable]
  )

  const handleChange = useCallback(
    value => {
      deviceManager.send({
        type: 'MIDI_INPUTS_SELECTED_CHANGE_REQUEST',
        id: value
      })
    },
    [deviceManager]
  )

  return (
    <>
      <Text style={styles.header}>
        Select MIDI Input
        {'\n'}
      </Text>
      {items.length > 0 && (
        <RadioGroup
          onChange={handleChange}
          items={items}
          value={inputIdSelected}
          color={globalSettingsColor['300'].color}
        />
      )}
      {items.length === 0 && (
        <Text style={styles.body2}>
          No MIDI inputs found, please connect via USB.
        </Text>
      )}
    </>
  )
}

export default React.memo(MidiInputSelector)
