import React, { useCallback, useEffect, useMemo, memo, useRef } from 'react'
import { FlatList } from 'react-native'
import TinyColor from '@ctrl/tinycolor'

import { Group } from './PickerList'
import Button from '../containers/Button'
import styles from '../styles/picker-custom'

export interface PickerListGroupShortcutsPropsOwn<T> {
  groups: Array<Group<T>>
  selectedGroup?: Group<T>
  viewableGroup?: Group<T>
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  onSelect(value: Group<T>): void
  isNarrowWidth: boolean
}

interface PickerListGroupShortcutsProps<T>
  extends PickerListGroupShortcutsPropsOwn<T> {
  isNarrowWidth: boolean
  buttonLength: number
}

interface PickerListGroupShortcutProps<T> {
  group: Group<T>
  onSelect: PickerListGroupShortcutsProps<T>['onSelect']
  isSelected: boolean
  isViewable: boolean
  isNarrowWidth: boolean
}

interface ItemLayout {
  length: number
  offset: number
  index: number
}

const PickerListGroupShortcut = memo(
  <T extends {}>(props: PickerListGroupShortcutProps<T>): JSX.Element => {
    const { group, onSelect, isSelected, isViewable, isNarrowWidth } = props
    const { Svg, color } = group

    const handlePress = useCallback((): void => {
      onSelect(group)
    }, [group, onSelect])

    const backgroundColor = useMemo(() => {
      const backgroundColor = TinyColor(color).toString()

      return backgroundColor
    }, [color])

    const text = useMemo(() => {
      return isNarrowWidth ? undefined : group.label
    }, [isNarrowWidth, group.label])

    return (
      <Button
        text={text}
        onPress={handlePress}
        type={isSelected ? 'contained' : isViewable ? 'outlined' : 'text'}
        SvgStart={Svg}
        color={backgroundColor}
        wrapperProps={{
          style: {
            flex: 1,
            borderWidth: 0,
            borderRadius: 0
          }
        }}
      />
    )
  }
)

function PickerListGroupShortcuts<T>(
  props: PickerListGroupShortcutsProps<T>
): JSX.Element {
  const {
    groups,
    onSelect,
    selectedGroup,
    viewableGroup,
    buttonLength,
    isNarrowWidth
  } = props

  const flatList = useRef<FlatList<any>>(null)

  const renderItem = useCallback(
    ({ item }): JSX.Element => {
      return (
        <PickerListGroupShortcut
          isNarrowWidth={isNarrowWidth}
          onSelect={onSelect}
          key={item.label}
          isViewable={viewableGroup === item}
          isSelected={selectedGroup === item}
          group={item}
        />
      )
    },
    [onSelect, selectedGroup, isNarrowWidth, viewableGroup]
  )

  const getItemLayout = useCallback(
    (_data, index): ItemLayout => {
      const length = buttonLength
      return {
        length,
        offset: length * index,
        index
      }
    },
    [buttonLength]
  )

  const initialScrollIndex = useMemo(
    (): number =>
      selectedGroup !== undefined ? groups.indexOf(selectedGroup) : 0,
    [groups, selectedGroup]
  )

  const data = groups

  const keyExtractor = useCallback(
    (_item, index): string => index.toString(),
    []
  )

  useEffect(() => {
    if (flatList.current !== null) {
      flatList.current.scrollToIndex({
        animated: false,
        index: initialScrollIndex
      })
    }
  }, [initialScrollIndex])

  const width = useMemo(() => {
    return isNarrowWidth ? buttonLength + 8 : buttonLength + 142
  }, [isNarrowWidth, buttonLength])

  return (
    <FlatList
      ref={flatList}
      keyExtractor={keyExtractor}
      data={data}
      renderItem={renderItem}
      getItemLayout={getItemLayout}
      style={[
        styles.shortcutsContainer,
        { maxWidth: width, paddingHorizontal: 8 }
      ]}
      contentContainerStyle={{ flex: 1 }}
    />
  )
}

export default memo(PickerListGroupShortcuts)
