import React, { useMemo } from 'react'
import { View } from 'react-native'

import ShortcutManagerComponent from './ShortcutManager'
import VirtualControllerContainer from '../containers/VirtualController'

import styles from '../styles/hardware'
import {
  VirtualController,
  ShortcutManagerMachine
} from 'midi-city-app-manager'

interface HardwareProps {
  isVisible: boolean
  isTouchInput: boolean
  isNarrowWidth: boolean
  isShortHeight: boolean
  width: number
  height: number
  top: number
  logoIsCompact: boolean
  virtualControllers: VirtualController.VirtualControllerMachineActor[]
  shortcutManager?: ShortcutManagerMachine.ShortcutManagerMachineActor
}

function Hardware(props: HardwareProps): JSX.Element {
  const {
    isTouchInput,
    width,
    height,
    isVisible,
    virtualControllers,
    shortcutManager,
    top
  } = props

  const styleVisibility = useMemo(() => {
    return isVisible ? { opacity: 100 } : { opacity: 0 }
  }, [isVisible])

  const styleTouch = isTouchInput
    ? styles.containerTouch
    : styles.containerNoTouch

  const viewStyle = useMemo(() => {
    return [
      styles.container,
      styleVisibility,
      styleTouch,
      { width, height, top }
    ]
  }, [width, height, styleTouch, styleVisibility, top])

  return (
    <>
      <View testID="hardware" dataSet={{ contain: 'layout' }} style={viewStyle}>
        {Array.from(
          virtualControllers.values() as Iterable<VirtualController.VirtualControllerInterpreter>
        ).map(virtualController => (
          <VirtualControllerContainer
            key={virtualController.state.context.channel}
            isTouch={isTouchInput}
            virtualController={virtualController}
          />
        ))}
      </View>

      {!isTouchInput && shortcutManager !== undefined && (
        <ShortcutManagerComponent shortcutManager={shortcutManager} />
      )}
    </>
  )
}

export default React.memo(Hardware)
