import { IPageFields } from '../types/generated/contentful'
import getContentfulCient from '../utils/contentful-client'
import moize from 'moize'

async function getPageContent(
  pathname: string
): Promise<IPageFields | undefined> {
  try {
    const entries = await getContentfulCient().getEntries<IPageFields>({
      content_type: 'page',
      'fields.route': pathname
    })
    const item = entries.items[0]
    return item.fields
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('missing contentful entry for page')
    // eslint-disable-next-line no-console
    console.error(e)
    return undefined
  }
}

export default moize(getPageContent, { isPromise: true, maxAge: 1000 * 60 })
