import React, { useContext, useCallback } from 'react'
import { View } from 'react-native'
import LayoutManagerContext from '../LayoutManagerContext'
import { usePartial } from 'midi-city-ui'
import { assertIsObject } from 'assertate'
import MidiSelector from '../MidiSelector'
import { AppMachineContext } from '../AppMachine'
import { Interpreter as TracksManager } from 'midi-city-app-manager/src/recording-manager'
import { SidebarStatus } from 'midi-city-app-manager/src/layout-manager'
import Nav, { Direction } from '../Nav'
import NavItem from '../NavItem'
import Button from '../Button'

import LogoSolo from '../../images/LogoSoloLight.svg'

function MainNav(): JSX.Element {
  const appMachine = useContext(AppMachineContext)
  const layoutManager = useContext(LayoutManagerContext)
  const isMobile = usePartial(appMachine, ({ context }) => context.isMobile)

  const layout = usePartial(layoutManager, ({ context }) => context.navLayout)

  const sidebarStatus = usePartial(
    layoutManager,
    ({ context }) => context.sidebarStatus
  )

  const recordingManager = usePartial(
    appMachine,
    ({ context }) => context.recordingManager
  ) as TracksManager | undefined

  const handleLogoPress = useCallback(() => {
    layoutManager.send({ type: 'SIDEBAR_OPEN' })
  }, [layoutManager])

  assertIsObject(layout)

  const isShowingLogo = sidebarStatus !== SidebarStatus.Pinned

  return (
    <View
      suppressHydrationWarning={true}
      style={{
        height: layout.height,
        width: layout.width,
        left: layout.x,
        top: layout.y,
        position: 'absolute'
      }}>
      <Nav height={layout.height} direction={Direction.Row} depth={2}>
        {isShowingLogo && (
          <NavItem flexGrow={0}>
            <Button
              onPress={handleLogoPress}
              type="text"
              SvgStartSize={48}
              SvgStart={LogoSolo}
            />
          </NavItem>
        )}
        {isMobile !== true && (
          <NavItem flexGrow={1}>
            {recordingManager !== undefined && (
              <MidiSelector
                style={{ height: '100%', marginHorizontal: 'auto' }}
                recordingManager={recordingManager}
              />
            )}
          </NavItem>
        )}
      </Nav>
    </View>
  )
}

export default React.memo(MainNav)
