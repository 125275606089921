import { StyleSheet } from 'react-native'
import { gray, blue } from '../utils/colors'

const TEXT_COLOR = gray['100'].color

export default StyleSheet.create({
  header: {
    color: TEXT_COLOR,
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: 0.15
  },

  subtitle1: {
    color: TEXT_COLOR,
    letterSpacing: 0.15,
    fontSize: 16
  },

  body2: {
    color: TEXT_COLOR,
    fontSize: 14,
    letterSpacing: 0.25,
    lineHeight: 18
  },

  caption: {
    color: TEXT_COLOR,
    fontSize: 12,
    letterSpacing: 0.4
  },

  link: {
    color: blue['500'].color
  },

  paragraph: {
    marginBottom: '0.8em'
  }
})
