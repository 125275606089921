import React, { useCallback, useRef, memo, useState } from 'react'
import { View, SectionList } from 'react-native'
import { flatten } from 'lodash-es'
import Svg from '*.svg'

import PickerListSectionHeader from './PickerListSectionHeader'
import PickerListGroupShortcuts from '../containers/PickerListGroupShortcuts'
import styles from '../styles/picker-custom'

import {
  useRenderItem,
  useHandleGetItemLayout,
  useSections,
  useInitialScrollIndex,
  useSelectedGroup
} from '../hooks/picker-list'

export interface GroupItem<T> {
  label: string
  value: T
}

export interface Group<T> {
  label: string
  items: Array<GroupItem<T>>
  color?: string
  Svg?: typeof Svg
}

export interface PickerListProps<T> {
  groups: Array<Group<T>>
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  onSelect(value: T): void
  selectedValue: T
  isNarrowWidth: boolean
}

export interface Section<T> {
  title: string
  color?: string
  Svg?: typeof Svg
  data: Array<SectionItem<T>>
}

export interface SectionItem<T> {
  label: string
  index: number
  key: number
  value: T
}

export enum PickerListLayoutDirection {
  Vertical,
  Horizontal
}

interface PickerListState<T> {
  viewableGroup?: Group<T>
}

export type PickerListSectionGroups<T> = Array<Group<T>>

export function getItemsFromGroups<T>(
  groups: PickerListSectionGroups<T>
): Array<GroupItem<T>> {
  return flatten(groups.map((group): Array<GroupItem<T>> => group.items))
}

export function getSelectedItemFromGroups<T>(
  groups: PickerListSectionGroups<T>,
  selectedValue: T
): GroupItem<T> | undefined {
  return getItemsFromGroups(groups).find(
    (item): boolean => item.value === selectedValue
  )
}

export function getItemGroup<T>(
  item: GroupItem<T>,
  groups: PickerListSectionGroups<T>
): Group<T> | undefined {
  return groups.find((group): boolean => group.items.includes(item))
}

function PickerList<T>(props: PickerListProps<T>): JSX.Element {
  const { groups, onSelect, selectedValue, isNarrowWidth } = props

  const sections = useSections(groups)

  const sectionList = useRef<SectionList<SectionItem<T>>>(null)

  const isMultiGroup = groups.length > 1

  const useHeaders = true
  const handleGetItemLayout = useHandleGetItemLayout(useHeaders)

  const renderItem = useRenderItem(selectedValue, onSelect)

  const selectedGroup = useSelectedGroup(selectedValue, groups)

  const [state, setState] = useState<PickerListState<T>>({
    viewableGroup: selectedGroup
  })

  const renderSectionHeader = useCallback(
    ({ section: { title, color, Svg } }): JSX.Element | null => {
      return isMultiGroup ? (
        <PickerListSectionHeader title={title} color={color} Svg={Svg} />
      ) : null
    },
    [isMultiGroup]
  )

  const handleShortcutSelect = useCallback(
    (group: Group<T>): void => {
      const value = group.items[0].value

      if (value === selectedValue) {
        return
      }

      onSelect(value)

      if (sectionList.current?.scrollToLocation !== undefined) {
        const groupMatching = groups.find(
          (item): boolean => item.label === group.label
        )
        const groupIndex = groups.indexOf(
          groupMatching !== undefined ? groupMatching : groups[0]
        )

        sectionList.current.scrollToLocation({
          sectionIndex: groupIndex,
          itemIndex: 0,
          viewOffset: 80,
          viewPosition: 0
        })
      }
    },
    [onSelect, groups, selectedValue]
  )

  const needsInitialScrollIndex = sectionList.current === null
  const initialScrollIndex = useInitialScrollIndex(
    selectedValue,
    groups,
    needsInitialScrollIndex
  )

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems }): void => {
      if (viewableItems.length > 0) {
        const firstViewableSection = viewableItems[0].section
        const firstViewableGroup =
          groups[sections.indexOf(firstViewableSection)]
        if (state.viewableGroup !== firstViewableGroup) {
          setState({ viewableGroup: firstViewableGroup })
        }
      }
    },
    [groups, sections, state.viewableGroup]
  )

  return (
    <View
      style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
      {isMultiGroup && (
        <PickerListGroupShortcuts
          isNarrowWidth={isNarrowWidth}
          onSelect={handleShortcutSelect}
          groups={groups}
          viewableGroup={state.viewableGroup}
          selectedGroup={selectedGroup}
        />
      )}
      <SectionList
        style={styles.scrollContainer}
        onViewableItemsChanged={handleViewableItemsChanged}
        initialNumToRender={20}
        viewabilityConfig={{
          minimumViewTime: 50,
          itemVisiblePercentThreshold: 10,
          waitForInteraction: false
        }}
        ref={sectionList as any}
        sections={sections}
        initialScrollIndex={initialScrollIndex}
        getItemLayout={handleGetItemLayout}
        renderItem={renderItem}
        renderSectionHeader={useHeaders ? renderSectionHeader : undefined}
      />
    </View>
  )
}

export default memo(PickerList)
