import React, { useCallback } from 'react'

import ZoomButtons, { ZoomButtonsPropsOwn } from '../components/ZoomButtons'
import { KeyboardLayoutStyle } from 'midi-city-shared-types'
import { assertIsNumber } from 'assertate'
import { usePartial } from 'midi-city-ui'

function ZoomButtonsContainer(props: ZoomButtonsPropsOwn): JSX.Element | null {
  const { keyboardMachine } = props
  const layoutStyle = usePartial(
    keyboardMachine,
    ({ context }) => context.layoutStyle
  )

  const isMaxZoomIn = layoutStyle === KeyboardLayoutStyle.Single
  const isMaxZoomOut = layoutStyle === KeyboardLayoutStyle.Quad

  const handleChange = useCallback(
    value => {
      keyboardMachine.send({ type: 'LAYOUT_STYLE_UPDATE_REQUEST', value })
    },
    [keyboardMachine]
  )

  assertIsNumber(layoutStyle)

  return (
    <ZoomButtons
      {...props}
      onChange={handleChange}
      layoutStyle={layoutStyle}
      isMaxZoomOut={isMaxZoomOut}
      isMaxZoomIn={isMaxZoomIn}
    />
  )
}

export default React.memo(ZoomButtonsContainer)
