import React, { useCallback, useMemo } from 'react'
import { ViewProps } from 'react-native'
import dynamic from 'next/dynamic'

import Picker from '../containers/PickerCustom'
import { Interpreter as MidiFileManagerInterpreter } from 'midi-city-app-manager/src/recording-manager'
import { usePartial } from 'midi-city-ui'

const Icon = dynamic(
  import(
    '../images/material-design-icons/av/svg/production/ic_library_music_24px.svg'
  ),
  {
    ssr: false
  }
)

interface MidiSelectorProps extends ViewProps {
  recordingManager: MidiFileManagerInterpreter
}

function MidiSelector({
  recordingManager,
  style
}: MidiSelectorProps): JSX.Element | null {
  const isRecording = false

  const midis = usePartial(recordingManager, ({ context }) => context.midis)
  const idSelected = usePartial(
    recordingManager,
    ({ context }) => context.idSelected
  )

  const handleValueChange = useCallback(
    (midiId: string) => {
      recordingManager.send({ type: 'LOAD_SOURCE_REQUEST', id: midiId })
    },
    [recordingManager]
  )

  const color = 'white'

  const items = useMemo(() => {
    if (midis === undefined) {
      return undefined
    }
    return midis.map(midi => {
      const trackName = midi.header.name
      return {
        label: trackName,
        value: midi.header.name
      }
    })
  }, [midis])

  if (items === undefined) {
    return null
  }

  const groups = [{ label: 'tracks', items }]

  return (
    <Picker
      disabled={isRecording}
      useStepper={true}
      Svg={Icon}
      style={style}
      color={color}
      onSelect={handleValueChange}
      modal="MidiSelector"
      dialogTitle="Change Track"
      selectedValue={idSelected}
      groups={groups}
      testID="recording-selector"
    />
  )
}

export default React.memo(MidiSelector)
