import React, { memo, FC, cloneElement } from 'react'
import { View, ViewProps } from 'react-native'

import { ButtonProps } from './Button'
import styles from '../styles/button-group'

export enum ButtonGroupButtonPosition {
  first,
  center,
  last
}

interface ButtonGroupProps extends ViewProps {
  color?: ButtonProps['color']
}

const ButtonGroup: FC<ButtonGroupProps> = memo(
  (props): JSX.Element => {
    const { children, color } = props
    const childrenCount = React.Children.count(children)
    const childrenEnhanced = React.Children.map(
      children,
      (child, index): React.ReactElement => {
        const isFirst = index === 0
        const isLast = index === childrenCount - 1

        let position

        if (isFirst) {
          position = ButtonGroupButtonPosition.first
        } else if (isLast) {
          position = ButtonGroupButtonPosition.last
        } else {
          position = ButtonGroupButtonPosition.center
        }

        return cloneElement(child as React.ReactElement<any>, {
          position,
          color
        })
      }
    )
    return (
      <View style={[styles.container, props.style]}>{childrenEnhanced}</View>
    )
  }
)

export default ButtonGroup
