import { StyleSheet } from 'react-native'
import stylesText from './text'

import { depth1, depth2, depth3, shadowColor } from '../utils/colors'

export const OPTION_HEIGHT = 48
export const PICKER_HEADER_HEIGHT = 30

const BACKGROUND_COLOR = depth1

export default StyleSheet.create({
  scrollContainer: {
    flexGrow: 1,
    minWidth: '50%',
    backgroundColor: BACKGROUND_COLOR
  },

  buttonMain: {
    height: '100%'
  },

  button: {
    justifyContent: 'flex-start',
    height: '100%',
    minHeight: 'auto'
  },

  buttonText: {
    textAlign: 'left'
  },

  option: {
    height: OPTION_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    flexShrink: 0
  },

  optionText: StyleSheet.flatten([
    stylesText.body2,
    {
      flexShrink: 1
    }
  ]),

  header: {
    height: PICKER_HEADER_HEIGHT,
    paddingHorizontal: 8,
    backgroundColor: depth2,
    shadowColor,
    shadowRadius: 10,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },

  headerText: StyleSheet.flatten(stylesText.caption),

  shortcutsContainer: {
    backgroundColor: depth3,
    zIndex: 3,
    shadowColor,
    shadowRadius: 12,
    maxWidth: 200
  }
})
