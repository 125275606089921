import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'

import VirtualControllerNav from './VirtualControllerNav'
import TinyColor from '@ctrl/tinycolor'
import Surface from './Surface'

import styles from '../styles/track-controls'
import { VirtualControllerInterpreter } from 'midi-city-app-manager/src/virtual-controller'
import { View } from 'react-native'
import { LayoutRectangle } from 'midi-city-shared-types'

export interface VirtualControllerPropsOwn {
  virtualController: VirtualControllerInterpreter
  isTouch: boolean
}

interface VirtualControllerProps extends VirtualControllerPropsOwn {
  color: string
  layout: LayoutRectangle
  contentLayout: LayoutRectangle
  isCollapsed: boolean
  marginTop: number
}

const VirtualControllerKeyboard = dynamic(
  async () => await import('../containers/VirtualControllerKeyboard'),
  {
    ssr: false
  }
)

function VirtualController(props: VirtualControllerProps): JSX.Element {
  const {
    isTouch,
    color,
    contentLayout,
    layout,
    marginTop,
    virtualController
  } = props

  const borderColor = useMemo(() => {
    const tinyColor = TinyColor(color)
    return tinyColor.shade(35).toString() as string
  }, [color])

  return (
    <View
      testID={`virtual-controller-channel-${virtualController.state.context.channel}`}
      style={{
        height: layout.height,
        width: layout.width,
        marginTop,
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Surface
        depth={2}
        style={[
          styles.container,
          { width: contentLayout.width, height: contentLayout.height }
        ]}>
        <VirtualControllerNav
          virtualControllerMachine={virtualController}
          borderColor={borderColor}
          isTouch={isTouch}
        />
        <VirtualControllerKeyboard virtualController={virtualController} />
      </Surface>
    </View>
  )
}

export default React.memo(VirtualController)
