import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {},

  keys: {
    flex: 1
  },

  scrollContainer: {
    flex: 1
  },

  key: {
    position: 'absolute',
    minHeight: 'none'
  },

  keyContent: {
    height: '100%',
    width: '100%'
  }
})
