import React from 'react'

import { Text } from 'react-native'
import styles from '../styles/text'
import MidiInputDebugger from './MidiInputDebugger'

import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import MidiInputSelector from './MidiInputSelector'
import { usePartial } from 'midi-city-ui'

interface MidiInputContentProps {
  deviceManager: MidiDeviceManagerInterpreter
}

const MidiInputContentUnsupported = React.memo(
  (): JSX.Element => {
    return (
      <>
        <Text style={styles.header}>Your browser does not support WebMIDI</Text>
        <Text style={styles.body2}>
          {'\n'}
          Your browser does not support WebMIDI. If you would like to use MIDI
          input hardware, please switch to Chrome.
        </Text>
      </>
    )
  }
)

const MidiInputContentInstructions = React.memo(
  ({ deviceManager }: MidiInputContentProps): JSX.Element => {
    return (
      <>
        <Text style={styles.header}>{'\n'}MIDI Input Debugger</Text>
        <MidiInputDebugger deviceManager={deviceManager} />
        <Text style={styles.header}>{'\n'}Help</Text>
        <Text style={styles.body2}>
          {'\n'}
          Melodic events must be on on channel 1 and percussion events must be
          on channel 10. Use the above debugger to see the channel of the latest
          event you have sent.
          {'\n'}
          {'\n'}This feature is currently in BETA, please discuss this feature{' '}
          <Text
            accessibilityRole="link"
            href="https://community.midi.city/t/beta-midi-hardware-input-support/232"
            style={styles.link}>
            in our community forum thread
          </Text>
          .
        </Text>
      </>
    )
  }
)

function MidiInputContent({
  deviceManager
}: MidiInputContentProps): JSX.Element {
  const isSupported = usePartial(deviceManager, state =>
    state.matches('initialized.monitoringInputs')
  )

  return (
    <>
      {isSupported && <MidiInputSelector deviceManager={deviceManager} />}

      {!isSupported && <MidiInputContentUnsupported />}

      {isSupported && (
        <MidiInputContentInstructions deviceManager={deviceManager} />
      )}
    </>
  )
}

export default React.memo(MidiInputContent)
