import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef
} from 'react'
import { Text, ActivityIndicator, Animated, StyleSheet } from 'react-native'

import Logo from './Logo'
import { AppMachineContext } from './AppMachine'
import { gray } from '../utils/colors'
import { usePartial } from 'midi-city-ui'

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontSize: 16,
    color: gray[300].color
  }
})

function AppLoading(): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)
  const isVisible = usePartial(
    appMachine,
    state => state.matches('idle') || state.matches('initializing')
  )
  const [isDone, isDoneSet] = useState(false)

  const handleRest = useCallback(() => {
    isDoneSet(true)
  }, [])

  const opacity = useRef(new Animated.Value(0)).current
  const [hasAnimatedIn, setHasAnimatedIn] = useState(false)

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 100,
      duration: 100,
      useNativeDriver: true
    }).start(() => {
      setHasAnimatedIn(true)
    })
  }, [opacity])

  useEffect(() => {
    if (!isVisible && hasAnimatedIn) {
      Animated.timing(opacity, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: true
      }).start(handleRest)
    }
  }, [isVisible, opacity, handleRest, hasAnimatedIn])

  if (isDone) {
    return null
  }

  return (
    <Animated.View
      testID="app-loading"
      suppressHydrationWarning={true}
      style={[styles.wrapper, { opacity }]}>
      <Logo width={256} isCompact={false} />

      <Text style={styles.text}>Loading</Text>

      <ActivityIndicator
        testID="activity-indicator"
        color={gray[300].color}
        style={{ marginTop: 8 }}
      />
    </Animated.View>
  )
}

export default React.memo(AppLoading)
