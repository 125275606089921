import { Machine, Interpreter as XInterpreter } from 'xstate'
import { assign } from '@xstate/immer'
import { ignoredAction, unexpectedAction } from 'midi-city-xstate-utils'
import { ButtonType } from '../types'

export * as selectors from './selectors'
export interface Context {
  type: ButtonType
  color: string
}

export interface Schema {
  states: {
    loading: {
      states: {
        inactive: {}
        active: {}
      }
    }
    enabled: {
      states: {
        inactive: {}
        active: {
          states: {
            internal: {
              states: {
                inactive: {}
                active: {}
              }
            }
            external: {
              states: {
                inactive: {}
                active: {}
              }
            }
          }
        }
      }
    }
  }
}

export type Event =
  | { type: 'PRESS_IN' }
  | { type: 'PRESS_OUT' }
  | { type: 'PRESS' }
  | { type: 'DISABLE' }
  | { type: 'ENABLE' }
  | { type: 'LOADING_STARTED' }
  | { type: 'LOADING_STOPPED' }
  | { type: 'EXTERNAL_ACTIVATE' }
  | { type: 'EXTERNAL_DEACTIVATE' }
  | {
      type: 'CHANGE_TYPE'
      value: ButtonType
    }

export type Interpreter = XInterpreter<Context, Schema, Event>

const machine = Machine<Context, Schema, Event>(
  {
    id: 'button',
    strict: true,

    context: {
      type: 'outlined',
      color: 'white'
    },

    on: {
      CHANGE_TYPE: {
        actions: [assign((ctx, { value }) => (ctx.type = value))]
      },

      PRESS: {
        actions: ignoredAction
      },

      PRESS_IN: {
        actions: ignoredAction
      },

      PRESS_OUT: {
        actions: ignoredAction
      },

      LOADING_STARTED: {
        target: 'loading'
      },

      LOADING_STOPPED: {},

      '*': {
        actions: unexpectedAction
      }
    },

    type: 'parallel',

    states: {
      loading: {
        initial: 'inactive',
        states: {
          active: {
            on: {
              LOADING_STOPPED: {
                target: 'inactive'
              }
            }
          },
          inactive: {
            on: {
              LOADING_STARTED: {
                target: 'active'
              }
            }
          }
        }
      },

      enabled: {
        initial: 'active',
        states: {
          inactive: {
            on: {
              ENABLE: {
                target: 'active'
              }
            }
          },
          active: {
            on: {
              DISABLE: {
                target: 'inactive'
              },
              ENABLE: {
                actions: ignoredAction
              }
            },
            type: 'parallel',
            states: {
              external: {
                initial: 'inactive',
                states: {
                  inactive: {
                    on: {
                      EXTERNAL_ACTIVATE: {
                        target: 'active'
                      },
                      EXTERNAL_DEACTIVATE: {
                        actions: ignoredAction
                      }
                    }
                  },
                  active: {
                    on: {
                      EXTERNAL_DEACTIVATE: {
                        target: 'inactive'
                      }
                    }
                  }
                }
              },

              internal: {
                initial: 'inactive',
                states: {
                  inactive: {
                    on: {
                      PRESS_IN: {
                        target: 'active'
                      },

                      PRESS: {
                        actions: 'onPress'
                      }
                    }
                  },
                  active: {
                    entry: ['onPressIn'],
                    exit: ['onPressOut'],
                    on: {
                      PRESS_OUT: {
                        target: 'inactive'
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  {
    actions: {
      onPressOut: ignoredAction,
      onPress: ignoredAction,
      onPressIn: ignoredAction
    }
  }
)

export default machine
