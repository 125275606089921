import React, { useCallback, useMemo } from 'react'
import LeftSvg from '../images/material-design-icons/navigation/svg/production/ic_chevron_left_48px.svg'
import RightSvg from '../images/material-design-icons/navigation/svg/production/ic_chevron_right_48px.svg'

import ButtonGroup from './ButtonGroup'
import Button from '../containers/Button'

interface NumberPickerProps {
  value: number
  minValue: number
  maxValue: number
  label: string
  onChange: (value: number) => void
  color?: string
  disabled: boolean
}

function NumberPicker({
  value,
  onChange,
  minValue,
  maxValue,
  label,
  color,
  disabled = false
}: NumberPickerProps): JSX.Element {
  const handleDecrement = useCallback(() => {
    onChange(value - 1)
  }, [onChange, value])

  const handleIncrement = useCallback(() => {
    onChange(value + 1)
  }, [onChange, value])

  const isMin = useMemo(() => value === minValue, [value, minValue])

  const isMax = useMemo(() => value === maxValue, [maxValue, value])

  return (
    <ButtonGroup color={color}>
      <Button
        disabled={disabled || isMin}
        onPress={handleDecrement}
        SvgStart={LeftSvg}
        type="text"
      />
      <Button
        disabled={disabled}
        type="text"
        label={label}
        text={value}
        textAlign="center"
        wrapperProps={{ style: { alignItems: 'center' } }}
      />
      <Button
        disabled={disabled || isMax}
        onPress={handleIncrement}
        SvgStart={RightSvg}
        type="text"
      />
    </ButtonGroup>
  )
}

export default React.memo(NumberPicker)
