import { useMemo } from 'react'

import { Group } from '../../components/PickerList'

import {
  useFlatItems,
  useSelectedGroupItem,
  useSelectedGroup
} from '../picker-list'

export default function useInitialScrollIndex<T>(
  selectedValue: T,
  groups: Array<Group<T>>,
  needsInitialScrollIndex: boolean
): number | undefined {
  const selectedGroup = useSelectedGroup(selectedValue, groups)
  const selectedItem = useSelectedGroupItem(selectedValue, groups)
  const items = useFlatItems(groups)
  return useMemo((): number | undefined => {
    if (
      selectedGroup === undefined ||
      selectedItem === undefined ||
      !needsInitialScrollIndex
    ) {
      return undefined
    }
    const selectedGroupIndex = groups.indexOf(selectedGroup)
    return selectedGroupIndex * 2 + items.indexOf(selectedItem) - 2
  }, [selectedGroup, groups, items, selectedItem, needsInitialScrollIndex])
}
