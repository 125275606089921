import { StyleSheet } from 'react-native'
import { depth1 } from '../utils/colors'

export const ROW_BOTTOM_BACKGROUND_COLOR = depth1

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
  },

  containerNoTouch: {},

  containerTouch: {
    height: '100%',
    justifyContent: 'flex-end'
  },

  containerNarrow: {},

  containerShort: {},

  logo: {
    marginRight: 8
  },

  rowBottom: {
    flex: 1,
    backgroundColor: ROW_BOTTOM_BACKGROUND_COLOR
  },

  rowBottomShort: {
    height: 'auto'
  },

  rowBottomTouch: {
    maxHeight: 300
  },

  visualizerWrapper: {
    flex: 1,
    height: 48,
    padding: 8
  }
})
