import React, { useMemo, useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { KeyboardContext, KeyRenderLabelProps } from '../containers/Keyboard'
import { Keys, usePartial } from 'midi-city-ui'
import { assertIsObject } from 'assertate'

interface KeysWrapperProps {
  renderKeyLabel: (props: KeyRenderLabelProps) => JSX.Element
  color: string
}

function KeysWrapper({ renderKeyLabel, color }: KeysWrapperProps): JSX.Element {
  const keyboardMachine = useContext(KeyboardContext)
  const contentLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.contentLayout
  )

  assertIsObject(contentLayout)

  const style = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          flex: 1,
          width: contentLayout.width
        }
      }),
    [contentLayout.width]
  )

  // TODO fix isTouch
  return (
    <View testID="Keyboard-Keys" style={style.wrapper}>
      <Keys renderKeyLabel={renderKeyLabel} color={color} isTouch={false} />
    </View>
  )
}

export default React.memo(KeysWrapper)
