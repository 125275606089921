import { StyleSheet } from 'react-native'
import { gray, depth2, depth4, shadowColor } from '../utils/colors'

const BACKGROUND_COLOR = gray['900'].color
const BORDER_COLOR = gray['800'].color

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    height: '100%',
    width: '100%',
    zIndex: 100
  },

  containerWithPadding: {
    padding: 16
  },

  containerTouch: {
    maxHeight: undefined
  },

  containerInner: {
    backgroundColor: BACKGROUND_COLOR,
    display: 'flex',
    overflow: 'hidden',
    marginHorizontal: 'auto'
  },

  header: {
    backgroundColor: depth4,
    shadowColor,
    shadowRadius: 12,
    borderBottomWidth: 1,
    borderBottomColor: depth2,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 4
  },

  footer: {
    backgroundColor: BACKGROUND_COLOR,
    borderColor: BORDER_COLOR,
    borderTopWidth: 1,
    padding: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  scrollContainer: {
    flex: 1
  }
})
