import React, { useCallback } from 'react'

import ZoomInSvg from '../images/material-design-icons/action/svg/production/ic_zoom_in_24px.svg'
import ZoomOutSvg from '../images/material-design-icons/action/svg/production/ic_zoom_out_24px.svg'

import ButtonGroup from './ButtonGroup'
import Button from '../containers/Button'
import { KeyboardMachineInterpreter } from 'midi-city-app-manager/src/keyboard-machine'
import { KeyboardLayoutStyle } from 'midi-city-shared-types'

export interface ZoomButtonsPropsOwn {
  keyboardMachine: KeyboardMachineInterpreter
  color: string
}

export interface ZoomButtonsPropsProvided {
  isMaxZoomIn: boolean
  isMaxZoomOut: boolean
  onChange: (value: KeyboardLayoutStyle) => void
  layoutStyle: KeyboardLayoutStyle
}

export interface ZoomButtonsProps
  extends ZoomButtonsPropsProvided,
    ZoomButtonsPropsOwn {}

function ZoomButtons(props: ZoomButtonsProps): JSX.Element {
  const { isMaxZoomOut, isMaxZoomIn, color, onChange, layoutStyle } = props

  const handleZoomIn = useCallback(() => {
    onChange(layoutStyle - 1)
  }, [layoutStyle, onChange])

  const handleZoomOut = useCallback(() => {
    onChange(layoutStyle + 1)
  }, [layoutStyle, onChange])

  return (
    <ButtonGroup color={color}>
      <Button
        type="text"
        onPress={handleZoomOut}
        SvgStart={ZoomOutSvg}
        disabled={isMaxZoomOut}
      />
      <Button
        type="text"
        onPress={handleZoomIn}
        SvgStart={ZoomInSvg}
        disabled={isMaxZoomIn}
      />
    </ButtonGroup>
  )
}

export default React.memo(ZoomButtons)
