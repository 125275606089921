import React, { useMemo } from 'react'

import Key, {
  KeyPropsOwn,
  KeyRenderLabelProps as KeyRenderLabelPropsT
} from '../components/Keyboard/Key'

import { getKeyLayout } from 'midi-city-app-manager/src/keyboard-machine/utils'
import { usePartial } from 'midi-city-ui'

function KeyContainer(props: KeyPropsOwn): JSX.Element {
  const { keyMachine } = props
  const number = usePartial(keyMachine, state => state.context.number)
  const numKeys = usePartial(keyMachine, state => state.context.numKeys)
  const keyboardLayout = usePartial(
    keyMachine,
    state => state.context.keyboardLayout
  )

  const keyboardLayoutStyle = usePartial(
    keyMachine,
    state => state.context.keyboardLayoutStyle
  )

  const dimensions = useMemo(() => {
    return getKeyLayout(number, numKeys, keyboardLayout, keyboardLayoutStyle)
  }, [number, numKeys, keyboardLayout, keyboardLayoutStyle])

  return <Key {...props} dimensions={dimensions} />
}

export default React.memo(KeyContainer)
export type KeyRenderLabelProps = KeyRenderLabelPropsT
