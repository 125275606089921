import React, { memo, useContext } from 'react'

import Dialog, { DialogOwnProps } from '../components/Dialog'
import { LayoutManagerContext, usePartial } from 'midi-city-ui'
import { assertIsObject } from 'assertate'

export interface DialogContainerProps {
  width: number
  height: number
}

const WIDTH_DEFAULT = 600
const HEIGHT_DEFAULT = 600

function DialogContainer(props: DialogOwnProps): JSX.Element | null {
  let { width, height } = props

  const layoutManager = useContext(LayoutManagerContext)

  const appLayout = usePartial(
    layoutManager,
    ({ context }) => context.appLayout
  )

  assertIsObject(appLayout)

  const windowWidth = appLayout.width

  const windowHeight = appLayout.height

  if (windowHeight === undefined || windowWidth === undefined) {
    return null
  }

  width = Math.min(windowWidth, width !== undefined ? width : WIDTH_DEFAULT)
  height = Math.min(
    windowHeight,
    height !== undefined ? height : HEIGHT_DEFAULT
  )

  return <Dialog {...props} width={width} height={height} />
}

export default memo(DialogContainer)
