import React, { useContext } from 'react'

import Hardware from '../components/Hardware'
import {
  AppMachineContext,
  usePartial,
  LayoutManagerContext
} from 'midi-city-ui'

import { assertIsObject } from 'assertate'

export default function HardwareContainer(): JSX.Element {
  const appMachine = useContext(AppMachineContext)
  const layoutManager = useContext(LayoutManagerContext)

  const isMobile = usePartial(appMachine, ({ context }) => context.isMobile)

  const hardwareLayout = usePartial(
    layoutManager,
    ({ context }) => context.hardwareLayout
  )
  const isNarrowWidth = usePartial(
    layoutManager,
    ({ context }) => context.isNarrowWidth
  )

  const virtualControllers =
    usePartial(
      appMachine,
      ({ context }) =>
        context.midiDeviceManager?.state.context.virtualControllers
    ) ?? []

  assertIsObject(hardwareLayout)

  const isVisible = true
  const isTouchInput = isMobile === true

  const isShortHeight = false

  const width = hardwareLayout.width

  const height = hardwareLayout.height

  const top = hardwareLayout.y

  const logoIsCompact = false

  const shortcutManager = appMachine.state.context.shortcutManager

  return (
    <>
      <Hardware
        virtualControllers={virtualControllers}
        shortcutManager={shortcutManager}
        isVisible={isVisible}
        width={width}
        logoIsCompact={logoIsCompact}
        height={height}
        top={top}
        isTouchInput={isTouchInput}
        isShortHeight={isShortHeight}
        isNarrowWidth={isNarrowWidth}
      />
    </>
  )
}
