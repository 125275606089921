import React from 'react'
import { View } from 'react-native'
import styles from './scroll-view-custom-styles'

function ScrollViewBoxShadow(): JSX.Element {
  return (
    <>
      <View style={[styles.boxShadow, styles.boxShadowLeft]} />
      <View style={[styles.boxShadow, styles.boxShadowRight]} />
    </>
  )
}

export default React.memo(ScrollViewBoxShadow)
