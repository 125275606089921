import { useMemo } from 'react'
import { flatten } from 'lodash-es'

import sectionListGetItemLayout from 'react-native-section-list-get-item-layout'

import useRenderItem from './picker-list/use-render-item'
import useInitialScrollIndex from './picker-list/use-initial-scroll-index'
import useSections from './picker-list/use-sections'

import { PICKER_HEADER_HEIGHT } from '../styles/picker-custom'
import useLayout from './use-layout'
import {
  PickerListSectionGroups,
  Group,
  GroupItem,
  getSelectedItemFromGroups,
  getItemGroup,
  PickerListLayoutDirection
} from '../components/PickerList'

type SectionListGetItemLayoutReturn = ReturnType<
  typeof sectionListGetItemLayout
>

export { useRenderItem, useInitialScrollIndex, useSections }

export function useHandleGetItemLayout(
  hasHeaders: boolean
): SectionListGetItemLayoutReturn {
  // TODO this is hardcode and button height should change based on device type
  const buttonLength = 40

  return useMemo((): SectionListGetItemLayoutReturn => {
    return sectionListGetItemLayout({
      getItemHeight: (): number => buttonLength,
      getSeparatorHeight: (): number => 0,
      getSectionHeaderHeight: (): number =>
        hasHeaders ? PICKER_HEADER_HEIGHT : 0,
      getSectionFooterHeight: (): number => 0,
      listHeaderHeight: 0
    })
  }, [buttonLength, hasHeaders])
}

export function useSelectedGroupItem<T>(
  selectedValue: T,
  groups: Array<Group<T>>
): GroupItem<T> | undefined {
  const selectedItem = getSelectedItemFromGroups(groups, selectedValue)
  return selectedItem
}

export function useSelectedGroup<T>(
  selectedValue: T,
  groups: Array<Group<T>>
): Group<T> | undefined {
  const selectedItem = useSelectedGroupItem(selectedValue, groups)
  return useMemo(() => {
    if (selectedItem === undefined) {
      return undefined
    }
    return getItemGroup(selectedItem, groups)
  }, [selectedItem, groups])
}

export function useFlatItems<T>(
  groups: PickerListSectionGroups<T>
): Array<GroupItem<T>> {
  return useMemo(
    (): Array<GroupItem<T>> =>
      flatten(groups.map((group): Array<GroupItem<T>> => group.items)),
    [groups]
  )
}

export function useLayoutDirection(): [
  PickerListLayoutDirection,
  ReturnType<typeof useLayout>[0],
  ReturnType<typeof useLayout>[1]
] {
  const [layout, handleLayout] = useLayout()

  let direction: PickerListLayoutDirection

  if (layout === null) {
    direction = PickerListLayoutDirection.Vertical
  } else {
    direction =
      layout.width < 500
        ? PickerListLayoutDirection.Vertical
        : PickerListLayoutDirection.Horizontal
  }

  return [direction, layout, handleLayout]
}
