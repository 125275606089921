import React from 'react'
import { Text, View } from 'react-native'
import Svg from '*.svg'

import styles from '../styles/picker-custom'

interface Props {
  title: string
  color?: string
  Svg?: typeof Svg
}

export default function PickerListSectionHeader(props: Props): JSX.Element {
  const { color, title } = props
  return (
    <View style={styles.header}>
      <Text style={[styles.headerText, { color }]}> {title} </Text>
    </View>
  )
}
