import React, { useCallback, KeyboardEvent } from 'react'
import useEventListener from 'use-typed-event-listener'
import { ShortcutManagerMachine } from 'midi-city-app-manager'

export interface KeyResponderContainerProps {
  shortcutManager: ShortcutManagerMachine.ShortcutManagerMachineActor
}

export type KeyResponderProps = KeyResponderContainerProps

const isShortcutEvent = (event: KeyboardEvent): boolean => {
  return (
    !event.metaKey &&
    !event.shiftKey &&
    !event.ctrlKey &&
    !event.altKey &&
    !event.repeat
  )
}

function ShortcutManagerComponent(props: KeyResponderProps): null {
  const { shortcutManager } = props

  const handleKeyDown = useCallback(
    (event): void => {
      if (!isShortcutEvent(event)) {
        return
      }

      event.preventDefault()

      shortcutManager.send({
        type: 'SHORTCUT_MANAGER_ON_REQUEST',
        code: event.code
      })
    },
    [shortcutManager]
  )

  const handleKeyUp = useCallback(
    (event): void => {
      if (!isShortcutEvent(event)) {
        return undefined
      }

      event.preventDefault()

      shortcutManager.send({
        type: 'SHORTCUT_MANAGER_OFF_REQUEST',
        code: event.code
      })
    },
    [shortcutManager]
  )

  useEventListener(window, 'keydown', handleKeyDown)
  useEventListener(window, 'keyup', handleKeyUp)

  return null
}

export default React.memo(ShortcutManagerComponent)
