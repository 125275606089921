import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    overflow: 'hidden'
  },

  keyboard: {
    flex: 1
  }
})
