import React, { useMemo } from 'react'
import { ViewStyle, LayoutRectangle } from 'react-native'
import { KeyboardKeyNumber } from 'midi-city-shared-types'

import styles from './styles'
import Surface from '../Surface'

import KeyContent, {
  KeyRenderLabelProps as KeyRenderLabelPropsT
} from '../../containers/KeyContent'
import { KeyboardKeyInterpreter } from 'midi-city-app-manager/src/keyboard-key-machine'

export interface KeyPropsOwn {
  keyNumber: KeyboardKeyNumber
  keyMachine: KeyboardKeyInterpreter
  renderKeyLabel: (props: KeyRenderLabelProps) => JSX.Element
  color?: string
  isTouch: boolean
}

interface KeyProps extends KeyPropsOwn {
  dimensions: LayoutRectangle
}

function Key(props: KeyProps): JSX.Element {
  const {
    keyNumber,
    keyMachine,
    color,
    renderKeyLabel,
    isTouch,
    dimensions
  } = props

  const { width } = dimensions

  const style = useMemo((): ViewStyle[] => {
    const { x, y, height } = dimensions
    const layoutStyle = {
      left: x,
      top: y,
      width: width,
      minWidth: width,
      height: height
    }

    return [styles.key, layoutStyle]
  }, [dimensions, width])

  const testID = `Key-${keyNumber as number}`

  return (
    <Surface
      dataSet={{ 'content-visibility-auto': true }}
      style={style}
      depth={2}
      testID={testID}>
      <KeyContent
        isTouch={isTouch}
        keyMachine={keyMachine}
        keyNumber={keyNumber}
        width={width}
        color={color}
        renderKeyLabel={renderKeyLabel}
      />
    </Surface>
  )
}

export type KeyRenderLabelProps = KeyRenderLabelPropsT
export default React.memo(Key)
