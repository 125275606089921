import React, { useCallback } from 'react'
import {
  Interpreter,
  getIsLoadingAnyTrack
} from 'midi-city-app-manager/src/recording-manager'

import { AppMachine } from 'midi-city-app-manager'
import { ViewProps } from 'react-native'

import Button from '../containers/Button'
import PlaySvg from '../images/material-design-icons/av/svg/production/ic_play_arrow_48px.svg'
import StopSvg from '../images/material-design-icons/av/svg/production/ic_stop_48px.svg'
import { red } from '../utils/colors'
import { usePartial } from 'midi-city-ui'

interface TrackPlayButtonProps extends ViewProps {
  recordingManager: Interpreter
}

function TrackPlayButton({
  recordingManager,
  style
}: TrackPlayButtonProps): JSX.Element {
  const isRecording = usePartial(recordingManager, AppMachine.getIsRecording)
  const isPlaying = usePartial(recordingManager, state =>
    state.matches({ initialized: { playState: 'active' } })
  )

  const isLoading = usePartial(
    recordingManager,
    state =>
      getIsLoadingAnyTrack(state.context) ||
      !state.matches({ initialized: { remoteFiles: 'loaded' } })
  )

  const color = isRecording ? red.A700.color : 'white'

  const handlePress = useCallback(() => {
    if (isPlaying) {
      recordingManager.send({ type: 'STOP_REQUEST' })
    } else {
      recordingManager.send({ type: 'START_REQUEST' })
    }
  }, [recordingManager, isPlaying])

  const Icon = isPlaying || isRecording ? StopSvg : PlaySvg

  return (
    <Button
      testID="track-play-button"
      dataSet={{
        'is-loading': isLoading,
        'is-playing': isPlaying
      }}
      isLoading={isLoading}
      color={color}
      wrapperProps={{ style }}
      onPress={handlePress}
      type="text"
      SvgStart={Icon}
    />
  )
}

export default React.memo(TrackPlayButton)
