import React, { useCallback, ReactNode } from 'react'
import { View, Text } from 'react-native'
import ArrowLeft from '../images/material-design-icons/navigation/svg/production/ic_arrow_back_48px.svg'
import { Portal } from 'react-native-portalize'

import styles from '../styles/dialog'
import stylesText from '../styles/text'
import Button from '../containers/Button'
import { gray } from '../utils/colors'
import { DialogContainerProps } from '../containers/Dialog'

export interface DialogOwnProps {
  children: ReactNode
  onClose?: () => void
  onCancel?: () => void
  isOpen: boolean
  title?: string
  width?: number
  height?: number
}

type DialogProps = DialogContainerProps & DialogOwnProps

function Dialog(props: DialogProps): JSX.Element | null {
  const { children, onClose, title, isOpen, width, height } = props

  const handleConfirm = useCallback((): void => {
    onClose?.()
  }, [onClose])

  if (!isOpen) {
    return null
  }

  return (
    <Portal>
      <View style={styles.container} pointerEvents="box-none">
        <View
          style={[styles.containerInner, { width, height }]}
          testID="dialog-modal-wrapper">
          <View style={styles.header}>
            <Button
              onPress={handleConfirm}
              color={gray['600'].color}
              SvgStart={ArrowLeft}
              testID="dialog-close-button"
              type="text"
            />
            <Text style={stylesText.header}> {title} </Text>
          </View>

          <View style={styles.scrollContainer}>{children}</View>
        </View>
      </View>
    </Portal>
  )
}

export default Dialog
