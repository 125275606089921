import { StyleSheet } from 'react-native'
import { gray } from '../../utils/colors'

export const color = gray['300'].color

export const BUTTON_DEFAULT_MIN_LENGTH = 40
export const BUTTON_TOUCH_MIN_LENGTH = 48

export default StyleSheet.create({
  container: {
    borderColor: color,
    borderWidth: 0,
    borderRadius: 2,
    position: 'relative',
    minWidth: BUTTON_DEFAULT_MIN_LENGTH,
    minHeight: BUTTON_DEFAULT_MIN_LENGTH,
    flex: 0,
    paddingHorizontal: 8,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexBasis: 'auto'
  },

  containerDisabled: {
    opacity: 0.5
  },

  containerTouch: {
    minWidth: BUTTON_TOUCH_MIN_LENGTH,
    minHeight: BUTTON_TOUCH_MIN_LENGTH
  },

  containerWithOnlyIcon: {
    justifyContent: 'center',
    alignItems: 'center'
  },

  nonLabelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 24
  },

  contained: {},

  link: {
    minHeight: 48,
    paddingHorizontal: 16
  },

  outlined: {
    borderWidth: 1
  },

  text: {
    borderWidth: 0
  },

  buttonBackground: {
    position: 'absolute',
    zIndex: 0,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: color
  },

  buttonBackgroundContained: {},

  buttonBackgroundOutlined: {},

  buttonBackgroundText: {},
  buttonBackgroundLink: {},

  buttonLabel: {
    color,
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    userSelect: 'none',
    textTransform: 'uppercase',
    marginHorizontal: 8
  },

  buttonText: {
    color,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'left',
    userSelect: 'none',
    textTransform: 'uppercase',
    marginHorizontal: 8,
    flexGrow: 1
  },

  buttonTextLink: {
    textTransform: 'capitalize'
  },

  buttonTextWrapper: {
    flex: 1
  },

  buttonGroupChild: {},

  buttonGroupFirstChild: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  buttonGroupCenterChild: {
    borderRadius: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0
  },

  buttonGroupLastChild: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
})
