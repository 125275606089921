import React, { useMemo } from 'react'
import { KeyboardKeyNumber } from 'midi-city-shared-types'

import Button from '../Button'

import styles from './styles'
import { KeyboardKeyInterpreter } from 'midi-city-app-manager/src/keyboard-key-machine'

export interface KeyRenderLabelProps {
  keyNumber: KeyboardKeyNumber
  keyMachine: KeyboardKeyInterpreter
  width: number
  shortcutLabel?: string
  color?: string
}

export interface KeyContentPropsOwn {
  renderKeyLabel: (props: KeyRenderLabelProps) => JSX.Element
  keyMachine: KeyboardKeyInterpreter
  keyNumber: KeyboardKeyNumber
  width: number
  isTouch: boolean
  color?: string
}

interface KeyContentProps extends KeyContentPropsOwn {
  shortcutLabel?: string
  isVisible: boolean
  isOn: boolean
}

function KeyContent({
  renderKeyLabel,
  keyNumber,
  color,
  isVisible,
  shortcutLabel,
  isOn,
  width,
  keyMachine
}: KeyContentProps): JSX.Element {
  const KeyLabel = useMemo(() => {
    return renderKeyLabel({
      color,
      keyMachine,
      width,
      shortcutLabel,
      keyNumber
    })
  }, [color, shortcutLabel, keyNumber, renderKeyLabel, width, keyMachine])

  const testID = useMemo(() => `Key-${keyNumber as number}-Content`, [
    keyNumber
  ])

  return (
    <Button
      wrapperProps={{
        testID: testID,
        style: styles.keyContent
      }}
      isActive={isOn}
      type="custom"
      shouldOptimize={isVisible}
      dataSet={{ contain: 'layout' }}
      color={color}>
      {KeyLabel}
    </Button>
  )
}

export default React.memo(KeyContent)
