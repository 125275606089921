import { Machine, send, Interpreter as XInterpreter } from 'xstate'
import { LayoutRectangle } from 'react-native'
import { assign } from '@xstate/immer'
import { unexpectedAction, ignoredAction } from 'midi-city-xstate-utils'
import { assertIsObject } from 'assertate'
import { choose } from 'xstate/lib/actions'

export enum Direction {
  Up,
  Right,
  Down,
  Left
}

interface Context {
  direction: Direction
  parentLayout?: LayoutRectangle
  layout?: LayoutRectangle
  offset?: {
    x: number
    y: number
  }
}

type Events =
  | {
      type: 'PARENT_LAYOUT_UPDATE'
      layout: LayoutRectangle
    }
  | {
      type: 'LAYOUT_UPDATE'
      layout: LayoutRectangle
    }
  | { type: 'INITIALIZED_CHECK' }
  | { type: 'OPEN' }
  | { type: 'CLOSE' }

interface Schema {
  states: {
    initializing: {}
    closed: {}
    opened: {}
  }
}

export type Interpreter = XInterpreter<Context, Schema, Events>

const machine = Machine<Context, Schema, Events>(
  {
    id: 'menu',
    strict: true,

    context: {
      direction: Direction.Up
    },

    on: {
      PARENT_LAYOUT_UPDATE: {
        actions: [
          'parentLayoutUpdate',
          send('INITIALIZED_CHECK'),
          choose([
            {
              actions: 'offsetUpdate',
              cond: (ctx): boolean => ctx.layout !== undefined
            }
          ])
        ]
      },

      LAYOUT_UPDATE: {
        actions: ['layoutUpdate', send('INITIALIZED_CHECK'), 'offsetUpdate']
      },

      INITIALIZED_CHECK: [
        {
          target: 'closed',
          in: 'initializing',
          cond: 'hasBothLayouts'
        },
        {
          actions: [ignoredAction]
        }
      ],

      '*': {
        actions: unexpectedAction
      }
    },

    initial: 'initializing',

    states: {
      initializing: {},
      closed: {
        on: {
          OPEN: {
            target: 'opened'
          },
          CLOSE: {
            actions: ignoredAction
          }
        }
      },
      opened: {
        on: {
          OPEN: {
            actions: ignoredAction
          },
          CLOSE: {
            target: 'closed'
          }
        }
      }
    }
  },
  {
    actions: {
      parentLayoutUpdate: assign((ctx, event) => {
        const { layout } = event as { layout: LayoutRectangle }
        ctx.parentLayout = layout
      }),

      layoutUpdate: assign((ctx, event) => {
        const { layout } = event as { layout: LayoutRectangle }
        ctx.layout = layout
      }),

      offsetUpdate: assign(ctx => {
        const { layout, direction, parentLayout } = ctx
        assertIsObject(layout)
        assertIsObject(parentLayout)

        const x =
          direction === Direction.Up || direction === Direction.Down
            ? parentLayout.x - layout.width * 0.5 + parentLayout.width * 0.5
            : direction === Direction.Left
            ? parentLayout.x - layout.width
            : 0

        const y =
          direction === Direction.Up || direction === Direction.Down
            ? parentLayout.y - layout.height
            : direction === Direction.Left
            ? parentLayout.y
            : 0

        ctx.offset = {
          x,
          y
        }
      })
    },

    guards: {
      hasBothLayouts: (ctx): boolean =>
        ctx.layout !== undefined && ctx.parentLayout !== undefined
    }
  }
)

export default machine
