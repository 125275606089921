import React, { useCallback } from 'react'

import { SectionListRenderItemInfo } from 'react-native'
import PickerItem from '../../components/PickerCustomItem'
import { SectionItem, PickerListProps } from '../../components/PickerList'

type Info<T> = SectionListRenderItemInfo<SectionItem<T>>

export default function useRenderItem<T>(
  selectedValue: PickerListProps<T>['selectedValue'],
  onSelect: PickerListProps<T>['onSelect']
): (item: Info<T>) => JSX.Element {
  return useCallback(
    ({ item, section }: Info<T>): JSX.Element => {
      const isSelected = item.value === selectedValue
      const handleItemPress = (value: T): void => {
        if (isSelected) {
          return
        }
        onSelect(value)
      }
      return (
        <PickerItem
          color={section.color}
          isSelected={isSelected}
          onPress={handleItemPress}
          label={item.label}
          value={item.value}
        />
      )
    },
    [selectedValue, onSelect]
  )
}
