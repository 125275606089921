import React, { memo, useEffect, useState, useContext } from 'react'
import { LayoutRectangle } from 'react-native'

import { AppMachine } from 'midi-city-app-manager'
import { KeyboardLayoutStyle, KeyboardKeyNumber } from 'midi-city-shared-types'

import GestureHandler from './GestureHandler'
import Key, { KeyRenderLabelProps } from '../../containers/Key'
import { KeyboardKeyInterpreter } from 'midi-city-app-manager/src/keyboard-key-machine'
import { KeyboardContext } from 'midi-city-ui'

export interface KeysPropsOwn {
  renderKeyLabel: (props: KeyRenderLabelProps) => JSX.Element
  isTouch: boolean
  color?: string
}

interface KeysProps extends KeysPropsOwn {
  numKeys: number
  wrapperLayout: LayoutRectangle
  keyboardLayout: KeyboardLayoutStyle
  onKeyOn: (key: number) => void
  onKeyOff: (key: number) => void
}

function KeyboardKeys(props: KeysProps): JSX.Element {
  const {
    numKeys,
    renderKeyLabel,
    wrapperLayout,
    onKeyOn,
    onKeyOff,
    color,
    keyboardLayout,
    isTouch
  } = props

  const keyboardMachine = useContext(KeyboardContext)

  const keys = AppMachine.getKeyboardKeys(keyboardMachine.state)

  const [hasRendered, setRendered] = useState(false)

  useEffect(() => setRendered(true), [])

  return (
    <>
      {hasRendered &&
        keys.map(
          (key): JSX.Element => (
            <Key
              isTouch={isTouch}
              key={key.id}
              keyNumber={key.state.context.number as KeyboardKeyNumber}
              keyMachine={key as KeyboardKeyInterpreter}
              color={color}
              renderKeyLabel={renderKeyLabel}
            />
          )
        )}
      {hasRendered && (
        <GestureHandler
          keyboardLayout={keyboardLayout}
          numKeys={numKeys}
          keyOn={onKeyOn}
          keyOff={onKeyOff}
          layout={wrapperLayout}
        />
      )}
    </>
  )
}

export default memo(KeyboardKeys)
