import React, { useContext } from 'react'
import { View } from 'react-native'

import Nav, { Direction } from './Nav'
import NavItem from './NavItem'
import MidiInputButton from './MidiInputButton'
import RecordButton from './RecordButton'
import TrackPlayButton from './TrackPlayButton'
import { usePartial } from 'midi-city-ui'
import { GlobalInterpreter } from 'midi-city-sound-engine'
import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import LayoutManagerContext from './LayoutManagerContext'
import { AppMachineContext } from './AppMachine'
import TimingButton from './TimingButton'

import { Interpreter as TracksInterpreter } from 'midi-city-app-manager/src/recording-manager'

function MediaBar(): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)
  const layoutManager = useContext(LayoutManagerContext)

  const layout = usePartial(
    layoutManager,
    ({ context }) => context.footerLayout
  )

  const recordingManager = usePartial(
    appMachine,
    ({ context }) => context.recordingManager
  ) as TracksInterpreter | undefined

  const midiDeviceManager = usePartial(
    appMachine,
    ({ context }) =>
      context.midiDeviceManager as MidiDeviceManagerInterpreter | undefined
  )

  const isMobile =
    usePartial(appMachine, ({ context }) => context.isMobile) ?? false

  const recordingAvailable = usePartial(
    appMachine,
    ({ context }) => context.recordingAvailable === true
  )

  const height = layout?.height ?? 0
  const width = layout?.width ?? 0
  const top = layout?.y ?? 0

  const canUseMidi = !isMobile

  const global = usePartial(appMachine, ({ context }) => context.global) as
    | GlobalInterpreter
    | undefined

  if (midiDeviceManager === undefined || recordingManager === undefined) {
    return null
  }

  const padding = 8

  return (
    <View
      style={{
        width,
        height,
        top,
        position: 'absolute',
        alignItems: 'center',
        paddingVertical: padding
      }}>
      <Nav direction={Direction.Row} depth={4} height={height - padding * 2}>
        {canUseMidi && midiDeviceManager !== undefined && (
          <>
            <NavItem width={96}>
              {recordingManager !== undefined && (
                <TrackPlayButton
                  style={{ flexGrow: 1 }}
                  recordingManager={recordingManager}
                />
              )}
            </NavItem>

            {!isMobile && recordingAvailable && (
              <NavItem width={96}>
                <RecordButton />
              </NavItem>
            )}

            <NavItem width={96}>
              {global !== undefined && (
                <TimingButton recordingManager={recordingManager} />
              )}
            </NavItem>
            <NavItem width={96}>
              <MidiInputButton deviceManager={midiDeviceManager} />
            </NavItem>
          </>
        )}
      </Nav>
    </View>
  )
}

export default React.memo(MediaBar)
