import React, { useMemo, memo } from 'react'
import Svg from '*.svg'

import { Group, getSelectedItemFromGroups } from './PickerList'
import Button from '../containers/Button'
import { useCenterButtonWidth } from '../hooks/picker-custom'
import { useFlatItems } from '../hooks/picker-list'
import styles from '../styles/picker-custom'
import dynamic from 'next/dynamic'

const MenuSvg = dynamic(
  import(
    '../images/material-design-icons/navigation/svg/production/ic_menu_18px.svg'
  ),
  {
    ssr: false
  }
)

interface PickerTriggerMainProps<T> {
  Svg?: typeof Svg
  groups: Array<Group<T>>
  selectedValue: T
  onPress: () => void
  isCompact?: boolean
  color?: string
  position?: number
  disabled: boolean
}

function PickerTriggerMain<T>({
  groups,
  position,
  color,
  isCompact,
  onPress,
  Svg,
  selectedValue,
  disabled = false
}: PickerTriggerMainProps<T>): JSX.Element {
  const items = useFlatItems(groups)

  const selectedItem = getSelectedItemFromGroups(groups, selectedValue)

  const selectedItemIndex = useMemo(
    () => (selectedItem !== undefined ? items.indexOf(selectedItem) : 0),
    [items, selectedItem]
  )

  const centerButtonWidth = useCenterButtonWidth(items, isCompact)

  const triggerContent = useMemo(
    () =>
      isCompact === true || selectedItem === undefined
        ? selectedItemIndex
        : selectedItem.label,
    [isCompact, selectedItem, selectedItemIndex]
  )

  return (
    <Button
      disabled={disabled}
      position={position}
      color={color}
      type="text"
      wrapperProps={{
        style: [styles.buttonMain, { width: centerButtonWidth }]
      }}
      onPress={onPress}
      SvgStart={Svg}
      SvgEnd={MenuSvg}
      SvgEndSize={18}
      SvgEndOpacty={0.5}
      text={triggerContent}></Button>
  )
}

export default memo(PickerTriggerMain)
