import React from 'react'

import PickerListGroupShortcuts, {
  PickerListGroupShortcutsPropsOwn
} from '../components/PickerListGroupShortcuts'

function PickerListGroupShortcutsContainer<T>(
  props: PickerListGroupShortcutsPropsOwn<T>
): JSX.Element {
  // TODO
  // const buttonLength = useSelector((state: State) => getButtonMinLength(state))
  const buttonLength = 100
  return (
    <PickerListGroupShortcuts
      {...props}
      isNarrowWidth={props.isNarrowWidth}
      buttonLength={buttonLength}
    />
  )
}

export default React.memo(PickerListGroupShortcutsContainer)
