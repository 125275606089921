import { useCallback, useMemo, useState } from 'react'
import {
  PanResponder,
  PanResponderInstance,
  LayoutRectangle
} from 'react-native'

interface State {
  thumbXOffset: number | null
  grantPageX: number | null
}

interface Args {
  onScrollBeginDrag?: () => void
  onScrollEndDrag?: () => void
  onMoveX: (x: number) => void
  parentLayout: LayoutRectangle | null
  thumbWidth: number
  thumbX: number
}

export default function useTrackbarGestures(
  args: Args
): [PanResponderInstance, boolean] {
  const {
    onScrollBeginDrag,
    onScrollEndDrag,
    onMoveX,
    parentLayout,
    thumbWidth,
    thumbX
  } = args

  const [state, setState] = useState<State>({
    thumbXOffset: null,
    grantPageX: null
  })

  const onGrant = useCallback(
    ({ nativeEvent }): void => {
      if (parentLayout === null) {
        return
      }
      const thumbLeft = thumbX
      const gestureX = nativeEvent.locationX
      const thumbRight = thumbLeft + thumbWidth

      const isOnThumb = gestureX >= thumbLeft && gestureX <= thumbRight
      const thumbXOffset = isOnThumb ? gestureX - thumbLeft : thumbWidth / 2

      setState({
        thumbXOffset,
        grantPageX: nativeEvent.pageX - gestureX
      })

      if (onScrollBeginDrag !== undefined) {
        onScrollBeginDrag()
      }

      const maxX = parentLayout.width - thumbWidth
      const moveX = Math.min(gestureX - thumbXOffset, maxX)

      onMoveX(moveX)
    },
    [onMoveX, thumbWidth, thumbX, onScrollBeginDrag, parentLayout]
  )

  const onMove = useCallback(
    ({ nativeEvent }): void => {
      if (
        parentLayout !== null &&
        state.grantPageX !== null &&
        state.thumbXOffset !== null
      ) {
        const cursorX =
          nativeEvent.pageX - state.thumbXOffset - state.grantPageX
        const maxX = parentLayout.width - thumbWidth
        const moveX = Math.min(cursorX, maxX)
        onMoveX(moveX)
      }
    },
    [onMoveX, parentLayout, thumbWidth, state.thumbXOffset, state.grantPageX]
  )

  const onRelease = useCallback((): void => {
    setState({ thumbXOffset: null, grantPageX: null })
    if (onScrollEndDrag !== undefined) {
      onScrollEndDrag()
    }
  }, [onScrollEndDrag])

  const panResponder = useMemo(
    (): PanResponderInstance =>
      PanResponder.create({
        onStartShouldSetPanResponder: (): boolean => true,
        onStartShouldSetPanResponderCapture: (): boolean => true,
        onMoveShouldSetPanResponder: (): boolean => true,
        onMoveShouldSetPanResponderCapture: (): boolean => true,
        onPanResponderRelease: onRelease,
        onPanResponderMove: onMove,
        onPanResponderGrant: onGrant
      }),
    [onMove, onGrant, onRelease]
  )

  return [panResponder, state.thumbXOffset !== null]
}
