import React, { useCallback, useContext } from 'react'
import dynamic from 'next/dynamic'

import Button from '../containers/Button'
import { red } from '../utils/colors'
import { AppMachineContext } from './AppMachine'
import { usePartial } from 'midi-city-ui'
import { Interpreter as MidiFileManagerInterpreter } from 'midi-city-app-manager/src/recording-manager'
import { assertIsObject } from 'assertate'
import { AppMachine } from 'midi-city-app-manager'

const RecordSvg = dynamic(
  import(
    '../images/material-design-icons/av/svg/production/ic_fiber_manual_record_24px.svg'
  ),
  {
    ssr: false
  }
)

function RecordButton(): JSX.Element | null {
  const machine = useContext(AppMachineContext)
  const recordingManager = usePartial(
    machine,
    ({ context }) =>
      context.recordingManager as MidiFileManagerInterpreter | undefined
  )

  assertIsObject(recordingManager)

  const isRecording = usePartial(recordingManager, AppMachine.getIsRecording)

  const handlePress = useCallback(() => {
    recordingManager.send({ type: 'RECORD_REQUEST' })
  }, [recordingManager])

  if (isRecording) {
    return null
  }

  return (
    <Button
      onPress={handlePress}
      wrapperProps={{ style: { flexGrow: 1 } }}
      type="text"
      testID="record-button"
      color={red.A700.color}
      SvgStart={RecordSvg}
    />
  )
}

export default React.memo(RecordButton)
