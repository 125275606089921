import React, { useContext, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import { TouchableWithoutFeedback, View } from 'react-native'

import { AppMachineContext } from '../AppMachine'
import { usePartial } from 'midi-city-ui'
import Surface from '../Surface'
import LayoutManagerContext from '../LayoutManagerContext'
import { SidebarStatus } from 'midi-city-app-manager/src/layout-manager'

const AnimatedSurface = animated(Surface)

const DEPTH = 5

function Backdrop(): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)
  const layoutManager = useContext(LayoutManagerContext)

  // TODO this is a quickfix for dialog's not knowing that the backdrop
  // was clicked -- ideally this logic happens in the machines
  const page = usePartial(appMachine, ({ context }) => context.page)

  const menuIsOpen = usePartial(
    appMachine,
    ({ context }) => context.menuOpen !== undefined
  )

  const sidebarIsOpen = usePartial(
    layoutManager,
    ({ context }) => context.sidebarStatus === SidebarStatus.Opened
  )

  const dialogIsOpen = usePartial(
    appMachine,
    ({ context }) => context.modalOpen !== undefined
  )

  const isOpen = menuIsOpen || sidebarIsOpen || dialogIsOpen

  const handlePressIn = useCallback(() => {
    if (menuIsOpen) {
      appMachine.send({ type: 'MENU_CHANGE', menu: undefined })
    }

    if (sidebarIsOpen) {
      layoutManager.send({ type: 'SIDEBAR_CLOSE' })
    }

    if (dialogIsOpen) {
      appMachine.send({ type: 'MODAL_CHANGE', modal: undefined })
    }

    if (page !== '/') {
      appMachine.send({ type: 'PAGE_CHANGE', page: '/' })
    }
  }, [appMachine, page, layoutManager, menuIsOpen, sidebarIsOpen, dialogIsOpen])

  const style = useSpring({
    width: '100%',
    height: '100%',
    opacity: isOpen ? 0.5 : 0,
    position: 'absolute',
    backgroundColor: 'black',
    zIndex: DEPTH
  })

  if (!isOpen) {
    return null
  }

  return (
    // @ts-expect-error
    <AnimatedSurface depth={DEPTH} style={style}>
      <TouchableWithoutFeedback onPress={handlePressIn}>
        <View style={{ width: '100%', height: '100%' }} />
      </TouchableWithoutFeedback>
    </AnimatedSurface>
  )
}

export default React.memo(Backdrop)
