import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/browser'
import { ExtraErrorData, Dedupe } from '@sentry/integrations'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { CustomError } from 'ts-custom-error'

const SENTRY_DSN = process.env.SENTRY_DSN
const GIT_SHA = process.env.GIT_SHA

const BAD_CHROME_EXTENSION_REGEX = /Non-Error promise rejection captured with keys/i
const ON_READY_ERROR_SAFARI_REGEX = /null is not an object \(evaluating 'g.readyState'\)/i
const ON_READY_ERROR_CHROME_REGEX = /Cannot read property 'readyState' of null/i

const CHUNK_LOAD_ERROR_NAME = 'ChunkLoadError'

const IGNORED_MESSAGE_REGEXES = [
  BAD_CHROME_EXTENSION_REGEX,
  ON_READY_ERROR_SAFARI_REGEX,
  ON_READY_ERROR_CHROME_REGEX
]

const isProduction = process.env.NODE_ENV === 'production'

const shouldIgnoreMessage = (message: string): boolean =>
  IGNORED_MESSAGE_REGEXES.some(regex => regex.test(message))

export default function initSentry(): void {
  if (typeof SENTRY_DSN !== 'string') {
    return
  }

  Sentry.init({
    debug: !isProduction,
    dsn: SENTRY_DSN,
    release: GIT_SHA,
    maxBreadcrumbs: 100,

    integrations: [
      new ExtraErrorData({ depth: 100 }),
      new Dedupe(),
      new TracingIntegrations.BrowserTracing({
        traceFetch: true,
        traceXHR: true,
        tracingOrigins: ['localhost', /^\//, 'midi.city']
      })
    ],
    normalizeDepth: 100,
    tracesSampleRate: isProduction ? 0.3 : 1,
    beforeBreadcrumb: (breadcrumb, _hint) => {
      return breadcrumb.category === 'ui.click' ? null : breadcrumb
    },
    beforeSend: (event, hint): Sentry.Event | null => {
      if (hint === undefined) {
        return event
      }

      const { syntheticException, originalException } = hint

      if (
        syntheticException instanceof Error &&
        shouldIgnoreMessage(syntheticException.message)
      ) {
        return null
      }

      if (
        typeof originalException === 'string' &&
        shouldIgnoreMessage(originalException)
      ) {
        return null
      }

      // all of the code following assumes it is an Error
      if (!(originalException instanceof Error)) {
        return event
      }

      if (
        originalException instanceof CustomError ||
        originalException.name === CHUNK_LOAD_ERROR_NAME
      ) {
        event.fingerprint = [originalException.name]
      }

      if (originalException.name === CHUNK_LOAD_ERROR_NAME) {
        event.level = Severity.Info
      }

      const { message } = originalException

      if (shouldIgnoreMessage(message)) {
        return null
      }
      return event
    }
  })
}
