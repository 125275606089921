import React, { useContext, useRef, useEffect } from 'react'
import { Animated } from 'react-native'

import LayoutManagerContext from './LayoutManagerContext'
import { usePartial } from 'midi-city-ui'
import Nav, { Direction } from './Nav'
import NavItem from './NavItem'
import Logo from './Logo'
import Button from '../containers/Button'
import dynamic from 'next/dynamic'
import { AppMachineContext } from './AppMachine'
import Surface from './Surface'
import { SidebarStatus } from 'midi-city-app-manager/src/layout-manager'
import BannerAds from './BannerAds'
import CopyrightNotice from './CopyrightNotice'

const BugSvg = dynamic(
  import(
    '../images/material-design-icons/action/svg/production/ic_bug_report_24px.svg'
  )
)

const VoteSvg = dynamic(import('../images/how_to_vote-24px.svg'))
const ChatSvg = dynamic(
  import(
    '../images/material-design-icons/communication/svg/production/ic_chat_bubble_24px.svg'
  )
)
const ChangeSvg = dynamic(
  import(
    '../images/material-design-icons/action/svg/production/ic_change_history_24px.svg'
  )
)

const PlaySvg = dynamic(import('../images/play.svg'))

const InfoSvg = dynamic(
  import(
    '../images/material-design-icons/action/svg/production/ic_info_24px.svg'
  )
)

function Sidebar(): JSX.Element | null {
  const layoutManager = useContext(LayoutManagerContext)
  const appMachine = useContext(AppMachineContext)
  const page = usePartial(appMachine, ({ context }) => context.page)
  const isMobile = usePartial(appMachine, ({ context }) => context.isMobile)

  const sidebarLayout = usePartial(
    layoutManager,
    ({ context }) => context.sidebarLayout
  )

  const sidebarStatus = usePartial(
    layoutManager,
    ({ context }) => context.sidebarStatus
  )

  const depth = sidebarStatus === SidebarStatus.Opened ? 6 : 5

  const x = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (sidebarLayout === undefined) {
      return
    }
    Animated.spring(x, {
      toValue: sidebarLayout.x,
      useNativeDriver: false,
      bounciness: 0
    }).start()
  }, [sidebarLayout, x])

  // left: sidebarLayout?.x ?? 0,

  if (sidebarLayout === undefined) {
    return null
  }

  return (
    <Surface
      depth={depth}
      style={[
        { zIndex: depth, position: 'absolute', left: 0, top: 0 },
        { transform: [{ translateX: x }] }
      ]}>
      <Nav
        direction={Direction.Column}
        width={sidebarLayout.width}
        height={sidebarLayout.height}
        depth={3}>
        <NavItem>
          <Logo fill="#ffffff" isCompact={false} />
        </NavItem>

        <NavItem isActive={page === '/'}>
          <Button type="link" text="Play" SvgStart={PlaySvg} href="/" />
        </NavItem>

        <NavItem isActive={page === '/about'}>
          <Button type="link" SvgStart={InfoSvg} text="About" href="/about" />
        </NavItem>

        <NavItem>
          <Button
            SvgStart={ChatSvg}
            href="https://community.midi.city"
            external={true}
            type="link"
            text="community chat"
          />
        </NavItem>

        <NavItem>
          <Button
            SvgStart={BugSvg}
            href="https://community.midi.city/c/support/bugs"
            type="link"
            external={true}
            text="report a bug"
          />
        </NavItem>

        <NavItem>
          <Button
            SvgStart={VoteSvg}
            href="https://community.midi.city/c/feature-requests"
            external={true}
            type="link"
            text="vote for new features"
          />
        </NavItem>

        <NavItem>
          <Button
            SvgStart={ChangeSvg}
            href="https://community.midi.city/c/changelog"
            wrapperProps={{
              accessibilityRole: 'link'
            }}
            external={true}
            type="link"
            text="changelog"
          />
        </NavItem>

        {isMobile !== true && <BannerAds />}
        <CopyrightNotice />
      </Nav>
    </Surface>
  )
}

export default React.memo(Sidebar)
