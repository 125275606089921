import React, { useCallback, useMemo, useContext } from 'react'

import { ScrollView } from 'react-native'
import Dialog from '../containers/Dialog'
import dialogStyles from '../styles/dialog'

import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import MidiInputContent from './MidiInputContent'
import { AppMachineContext } from './AppMachine'
import { usePartial } from 'midi-city-ui'

interface MidiInputDialogProps {
  deviceManager: MidiDeviceManagerInterpreter
}

function MidiInputDialog({ deviceManager }: MidiInputDialogProps): JSX.Element {
  const appMachine = useContext(AppMachineContext)

  const isOpen = usePartial(
    appMachine,
    ({ context }) => context.modalOpen === 'MidiInput'
  )

  const onClose = useCallback(() => {
    appMachine.send({ type: 'MODAL_CHANGE', modal: undefined })
  }, [appMachine])

  const children = useMemo(
    () => (
      <ScrollView style={dialogStyles.containerWithPadding}>
        <MidiInputContent deviceManager={deviceManager} />
      </ScrollView>
    ),
    [deviceManager]
  )

  return (
    <Dialog isOpen={isOpen} onClose={onClose} onCancel={onClose}>
      {children}
    </Dialog>
  )
}

export default React.memo(MidiInputDialog)
