import React, { useCallback, useContext } from 'react'

import Keys, { KeysPropsOwn } from '../components/Keyboard/Keys'
import { KeyboardGestureManagerInterpreter } from 'midi-city-app-manager/src/keyboard-gesture-manager-machine'
import { assertIsNumber, assertIsObject } from 'assertate'
import { KeyboardContext } from './Keyboard'
import { usePartial } from 'midi-city-ui'

function KeysContainer(props: KeysPropsOwn): JSX.Element {
  const keyboardMachine = useContext(KeyboardContext)

  const gestureManager = keyboardMachine.state.context
    .gestureManager as KeyboardGestureManagerInterpreter

  const numKeys = usePartial(keyboardMachine, ({ context }) => context.numKeys)
  assertIsNumber(numKeys)

  const contentLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.contentLayout
  )
  const wrapperLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.keysWrapperLayout
  )
  const keyboardLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.layoutStyle
  )

  assertIsObject(contentLayout)
  assertIsObject(wrapperLayout)
  assertIsNumber(keyboardLayout)

  const onKeyOn = useCallback(
    keyNumber => {
      gestureManager.send({
        type: 'KEYBOARD_GESTURE_KEY_ON_REQUEST',
        keyNumber
      })
    },
    [gestureManager]
  )

  const onKeyOff = useCallback(
    keyNumber => {
      gestureManager.send({
        type: 'KEYBOARD_GESTURE_KEY_OFF_REQUEST',
        keyNumber
      })
    },
    [gestureManager]
  )

  const propsProvided = {
    numKeys,
    contentLayout,
    wrapperLayout,
    keyboardLayout,
    onKeyOn,
    onKeyOff
  }

  return <Keys {...props} {...propsProvided} />
}

export default React.memo(KeysContainer)
