import React, { useCallback } from 'react'
import { Button, Text, TextInput, View } from 'react-native'
import {
  GetUsernameIsAvailableDocument,
  buildClient,
  UpdateAppUserSelfDocument,
  UpdateAppUserSelfPayload
} from 'midi-city-server'

import { Machine } from 'xstate'
import { useMachine } from '@xstate/react'
import { assign } from '@xstate/immer'
import { assertIsObject, assertIsString } from 'assertate'

const URL_BASE = process.env.URL_BASE
assertIsString(URL_BASE)
const apolloClient = buildClient(URL_BASE)

interface Context {
  input?: string
  persisted?: boolean
}

const machine = Machine<Context>({
  id: 'username-selector',
  strict: true,
  initial: 'invalid',
  context: {
    input: undefined,
    persisted: undefined
  },
  on: {
    INPUT_CHANGED: {
      actions: assign((ctx, { value }) => {
        ctx.input = value
      }),
      target: 'waiting'
    }
  },
  states: {
    persisted: {},
    invalid: {},
    waiting: {
      after: {
        500: 'checking'
      }
    },
    checking: {
      invoke: {
        id: 'checkValidity',
        src: async (context): Promise<boolean> => {
          const result = await apolloClient.query({
            query: GetUsernameIsAvailableDocument,
            variables: {
              usernameInput: context.input ?? ''
            }
          })
          return result.data.getUsernameIsAvailable === true
        },
        onDone: [
          {
            target: 'valid',
            cond: (_ctx, { data }): boolean => data === true
          },
          {
            target: 'invalid',
            cond: (_ctx, { data }): boolean => data !== true
          }
        ]
      }
    },
    valid: {
      initial: 'readyToSubmit',
      states: {
        readyToSubmit: {
          on: {
            SUBMIT: {
              target: 'submitting'
            }
          }
        },
        submitting: {
          invoke: {
            id: 'save',
            src: async (context): Promise<UpdateAppUserSelfPayload> => {
              const { input } = context
              assertIsString(input)
              const { data } = await apolloClient.mutate({
                mutation: UpdateAppUserSelfDocument,
                variables: {
                  username: input
                }
              })
              if (data !== undefined && data !== null) {
                const { updateAppUserSelf } = data
                assertIsObject(updateAppUserSelf)
                return updateAppUserSelf
              }
              throw new Error('unexpected condition')
            },
            onDone: {
              target: 'submitted'
            }
          }
        },
        submitted: {}
      }
    }
  }
})

function UsernameSelector(): JSX.Element {
  const [state, send] = useMachine(machine)

  const handleChange = useCallback(
    event => {
      const usernameInput = event.target.value
      send({ type: 'INPUT_CHANGED', value: usernameInput })
    },
    [send]
  )

  const canBeSubmitted = state.matches({ valid: 'readyToSubmit' })
  const canBeChanged = !state.matches({ valid: 'submitting' })

  return (
    <View testID="username-selector">
      <TextInput
        onChange={handleChange}
        editable={canBeChanged}
        style={{ backgroundColor: 'white' }}
      />
      <Text style={{ color: 'white' }}> {state.toStrings()}</Text>
      <Button
        disabled={!canBeSubmitted}
        title="submit"
        onPress={(): any => send({ type: 'SUBMIT' })}
      />
    </View>
  )
}

export default React.memo(UsernameSelector)
