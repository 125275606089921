import React, { memo, useCallback, useContext } from 'react'

import PresetSelector, {
  PresetSelectorPropsOwn
} from '../components/PresetSelector'
import { AppMachine } from 'midi-city-app-manager'
import { getPresetIcon, getPresetColor } from '../utils/preset'
import { AppMachineContext } from '../../'
import { usePartial } from 'midi-city-ui'
import { assertIsNumber } from 'assertate'

function PresetSelectorContainer(
  propsOwn: PresetSelectorPropsOwn
): JSX.Element {
  const { virtualController } = propsOwn
  const appMachine = useContext(AppMachineContext)
  const presets = usePartial(appMachine, ({ context }) =>
    AppMachine.getPresets(context)
  )

  const presetNumber = usePartial(
    virtualController,
    ({ context }) => context.presetNumber
  )

  const bankId = usePartial(virtualController, ({ context }) => context.bankId)
  const channel = usePartial(
    virtualController,
    ({ context }) => context.channel
  )

  assertIsNumber(presetNumber)
  assertIsNumber(bankId)

  const color = getPresetColor(presetNumber, bankId)
  const Svg = getPresetIcon(presetNumber, bankId)

  // TODO
  // const disabled = state.isRecording

  const changePreset = useCallback(
    ({ presetNumber, bankId }) =>
      virtualController.send({
        type: 'VIRTUAL_CONTROLLER_CHANGE_PRESET',
        presetNumber,
        bankId
      }),
    [virtualController]
  )

  const propsState = {
    presets,
    color,
    Svg,
    bankId,
    presetNumber,
    disabled: false,
    channel
  }

  return (
    <PresetSelector {...propsOwn} {...propsState} changePreset={changePreset} />
  )
}

export default memo(PresetSelectorContainer)
