import React, { useContext } from 'react'
import BannerAd from './BannerAd'
import { View } from 'react-native'
import NavItem from './NavItem'
import { AppMachineContext } from './AppMachine'
import { usePartial } from 'midi-city-ui'

function BannerAds(): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)

  const ads = usePartial(appMachine, ({ context }) => context.ads)

  if (ads === undefined) {
    return null
  }

  return (
    <NavItem flexGrow={1} flexShrink={1}>
      <View
        style={{
          flexGrow: 1,
          flexShrink: 1,
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
        {ads.map(ad => (
          <BannerAd key={ad._id} ad={ad} />
        ))}
      </View>
    </NavItem>
  )
}

export default React.memo(BannerAds)
