import React from 'react'

import VirtualController, {
  VirtualControllerPropsOwn
} from '../components/VirtualController'
import { AppMachine } from 'midi-city-app-manager'
import { getPresetColor } from '../utils/preset'
import { assertIsNumber, assertIsObject } from 'assertate'
import { VirtualControllerInterpreter } from 'midi-city-app-manager/src/virtual-controller'
import { usePartial } from 'midi-city-ui'

export const VirtualControllerContext = React.createContext(
  {} as VirtualControllerInterpreter
)

function VirtualControllerContainer(
  props: VirtualControllerPropsOwn
): JSX.Element | null {
  const { virtualController } = props
  const isCollapsed = usePartial(
    virtualController,
    AppMachine.getVirtualControllerIsOpen
  )
  const presetNumber = usePartial(
    virtualController,
    ({ context }) => context.presetNumber
  )
  const bankId = usePartial(virtualController, ({ context }) => context.bankId)
  const layout = usePartial(
    virtualController,
    AppMachine.getVirtualControllerLayout
  )
  const contentLayout = usePartial(
    virtualController,
    ({ context }) => context.contentLayout
  )

  assertIsObject(contentLayout)
  assertIsNumber(presetNumber)
  assertIsNumber(bankId)

  const color = getPresetColor(presetNumber, bankId)

  const marginTop = layout.y

  const propsProvided = {
    layout,
    contentLayout,
    color,
    isCollapsed,
    marginTop,
    virtualController
  }

  if (layout.height === 0) {
    return null
  }

  return (
    <VirtualControllerContext.Provider value={virtualController}>
      <VirtualController {...propsProvided} {...props} />
    </VirtualControllerContext.Provider>
  )
}

export default React.memo(VirtualControllerContainer)
