import React, { useEffect, useCallback, ReactNode } from 'react'
import log from 'loglevel'

import { AppLoading, Main, usePartial } from 'midi-city-ui'
import * as AppManager from 'midi-city-app-manager/src/app-machine'
import * as LayoutManager from 'midi-city-app-manager/src/layout-manager'
import { assertIsObject } from 'assertate'
import LayoutManagerContext from './LayoutManagerContext'
import { Dimensions, View, StyleSheet, InteractionManager } from 'react-native'
import { animated, useSpring } from 'react-spring'
import styles from '../styles'
import Sidebar from './Sidebar'
import { Host } from 'react-native-portalize'
import MainNav from './MainNav'
import Backdrop from './Backdrop'
import Notifications from './Notifications'
import { NotificationManagerInterpreter } from 'packages/app-manager/src/notification-manager'
import Dialog from '../containers/Dialog'
import UsernameSelector from './UsernameSelector'

const STYLES_FLATTENED = StyleSheet.flatten(styles.page)

interface AppMachineProps {
  appMachine: AppManager.Interpreter
  children?: ReactNode
}

export const AppMachineContext = React.createContext<AppManager.Interpreter>(
  (undefined as unknown) as AppManager.Interpreter
)

const AnimatedView = animated(View)

export default function AppMachineComponent({
  appMachine,
  children
}: AppMachineProps): JSX.Element {
  const isSupported = usePartial(
    appMachine,
    ({ context }) => context.isSupported
  )
  const layoutManager = appMachine.state.context
    .layoutManager as LayoutManager.LayoutManagerInterpreter

  assertIsObject(layoutManager)

  const isUninitialized = usePartial(appMachine, state =>
    state.matches('uninitialized')
  )

  const isInitializing = usePartial(appMachine, state =>
    state.matches('initializing')
  )

  const currentUser = usePartial(appMachine, state => state.context.currentUser)
  const needsUsername =
    currentUser !== undefined &&
    currentUser !== null &&
    currentUser.username === null

  const isLoading = isUninitialized || isInitializing

  const opacity = isLoading ? 0.0 : 1.0
  const [props, set] = useSpring(() => ({ ...STYLES_FLATTENED, opacity }))

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    set({ ...STYLES_FLATTENED, opacity })
  }, [opacity, set])

  const sendDimensions = useCallback(
    ({ window }) => {
      layoutManager.send({
        type: 'WINDOW_LAYOUT_UPDATE',
        layout: {
          x: 0,
          y: 0,
          width: window.width,
          height: window.height
        }
      })
    },
    [layoutManager]
  )

  useEffect(() => {
    sendDimensions({ window: Dimensions.get('window') })
    Dimensions.addEventListener('change', sendDimensions)
  }, [sendDimensions])

  const recordingManager = appMachine.state.context.recordingManager

  // TODO put this into machines
  // this is a hack to wait to load the channels until an idle loop
  useEffect(() => {
    if (!isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      InteractionManager.runAfterInteractions(() => {
        // this can happen in devmode reloads
        if (!appMachine.state.matches('ready')) {
          return
        }
        log.info('Main.tsx idle callback reached')
        appMachine.send('CHANNELS_LOAD_REQUEST')
        recordingManager?.send({ type: 'FETCH_ALL_REQUEST' })
      })
    }
  }, [isLoading, appMachine, recordingManager])

  const notificationManager = appMachine.state.context
    .notificationManager as NotificationManagerInterpreter

  return (
    <AppMachineContext.Provider value={appMachine}>
      <LayoutManagerContext.Provider value={layoutManager}>
        <Host>
          <AppLoading />
          {/*
        // @ts-expect-error */}
          <AnimatedView style={props as StyleProps}>
            {isSupported !== false && (
              <>
                <Sidebar />
                <Backdrop />
                <MainNav />
                <Main appMachine={appMachine} />
              </>
            )}
            {children}
            {needsUsername && (
              <Dialog isOpen={needsUsername}>
                <View>
                  <UsernameSelector />
                </View>
              </Dialog>
            )}
            <Notifications notificationManager={notificationManager} />
          </AnimatedView>
        </Host>
      </LayoutManagerContext.Provider>
    </AppMachineContext.Provider>
  )
}
