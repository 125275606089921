import React from 'react'
import dynamic from 'next/dynamic'
import { VisualizerPropsOwn } from '.'

const DynamicComponentWithNoSSR = dynamic(
  async () => await import('../../containers/Visualizer'),
  { ssr: false }
)

function VisualizerDynamicallyImported(props: VisualizerPropsOwn): JSX.Element {
  return <DynamicComponentWithNoSSR {...props}></DynamicComponentWithNoSSR>
}

export default React.memo(VisualizerDynamicallyImported)
