import React from 'react'
import { get } from 'lodash-es'

import KeyContent, {
  KeyContentPropsOwn,
  KeyRenderLabelProps as KeyRenderLabelPropsT
} from '../components/Keyboard/KeyContent'

import { usePartial } from 'midi-city-ui'

function KeyContentContainer(props: KeyContentPropsOwn): JSX.Element {
  const { keyMachine } = props

  const shortcut = null
  const isVisible = false
  const isOn = usePartial(
    keyMachine,
    state => state.matches('actual.on') || state.matches('forced.on')
  )

  const shortcutLabel = get(shortcut, 'label')
  const propsProvided = { isOn, isVisible, shortcutLabel }
  return <KeyContent {...props} {...propsProvided} />
}

export default React.memo(KeyContentContainer)
export type KeyRenderLabelProps = KeyRenderLabelPropsT
