import React from 'react'
import { View } from 'react-native'
import PickerCustomItem from './PickerCustomItem'

interface RadioGroupProps<T extends number | string> {
  value?: T
  items: Array<[string, T]>
  color?: string
  onChange: (value: T) => void
}

function RadioGroup<T extends number | string>({
  items,
  value,
  color,
  onChange
}: RadioGroupProps<T>): JSX.Element {
  return (
    <View>
      {items.map(([text, itemValue]) => (
        <PickerCustomItem
          key={itemValue}
          isSelected={itemValue === value}
          value={itemValue}
          color={color !== undefined ? color : 'white'}
          label={text}
          onPress={onChange}
        />
      ))}
    </View>
  )
}

export default React.memo(RadioGroup)
