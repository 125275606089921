import React, { useCallback, useState, useRef, PointerEvent } from 'react'
import { LayoutRectangle } from 'react-native'
import { omit } from 'lodash-es'

import { KeyboardLayoutStyle } from 'midi-city-shared-types'
import { getLocationKey } from 'midi-city-app-manager/src/keyboard-machine/utils'

interface Props {
  keyOn: (key: number) => void
  keyOff: (key: number) => void
  numKeys: number
  layout: LayoutRectangle
  keyboardLayout: KeyboardLayoutStyle
}

type IdentifierNoteMapping = Record<number, number | undefined>

function KeyboardGestureHandler(props: Props): JSX.Element {
  const [identifierNoteMapping, setState] = useState<IdentifierNoteMapping>({})

  const { keyOn, keyOff, layout, numKeys, keyboardLayout } = props

  const elRef = useRef<HTMLDivElement>(null)

  const handleKeyChange = useCallback(
    (
      key: number | null,
      pointerId: number,
      keyOnCurrent: number | undefined
    ) => {
      if (key === null) {
        return
      }

      const changedFromPreviousKey =
        keyOnCurrent !== undefined && keyOnCurrent !== key

      const isSameKey = keyOnCurrent === key

      if (changedFromPreviousKey) {
        keyOff(keyOnCurrent as number)
      }

      if (isSameKey) {
        return
      }

      const currentKeyAlreadyCaptured = Object.entries(
        identifierNoteMapping
      ).some(
        ([pointerIdMapping, keyId]) =>
          parseInt(pointerIdMapping) !== pointerId && keyId === key
      )

      // this key entered another key that was already activated
      if (currentKeyAlreadyCaptured) {
        setState(omit(identifierNoteMapping, [pointerId]))
        return
      }

      setState({
        ...identifierNoteMapping,
        [pointerId]: key
      })

      keyOn(key)
    },
    [keyOff, keyOn, identifierNoteMapping]
  )

  const handleMove = useCallback(
    (event: PointerEvent): void => {
      event.preventDefault()
      event.stopPropagation()

      const { pointerId, nativeEvent } = event

      if (
        elRef.current === null ||
        !elRef.current.hasPointerCapture(event.pointerId)
      ) {
        return
      }

      const keyOnCurrent = identifierNoteMapping[event.pointerId]

      const offsetX = nativeEvent.offsetX
      const offsetY = nativeEvent.offsetY

      const key = getLocationKey(
        offsetX,
        offsetY,
        numKeys,
        layout,
        keyboardLayout
      )

      handleKeyChange(key, pointerId, keyOnCurrent)
    },
    [layout, numKeys, keyboardLayout, identifierNoteMapping, handleKeyChange]
  )

  const handleUp = useCallback(
    (event): void => {
      event.preventDefault()
      event.stopPropagation()

      const { pointerId } = event

      if (identifierNoteMapping[pointerId] !== undefined) {
        const elRefCurrent = elRef.current as HTMLDivElement
        elRefCurrent.releasePointerCapture(pointerId)
      }
      const identifier = event.pointerId
      const noteOn = identifierNoteMapping[identifier]
      if (noteOn !== undefined && noteOn >= 0) {
        keyOff(noteOn)
      }
      setState(omit(identifierNoteMapping, [identifier]))
    },
    [keyOff, identifierNoteMapping]
  )

  const handleDown = useCallback(
    (event): void => {
      event.preventDefault()
      event.stopPropagation()

      if (elRef.current !== null) {
        elRef.current.setPointerCapture(event.pointerId)
        handleMove(event)
      }
    },
    [handleMove]
  )

  return (
    <div
      ref={elRef}
      onPointerDown={handleDown}
      onPointerUp={handleUp}
      onPointerMove={handleMove}
      touch-action="none"
      style={{
        width: '100%',
        position: 'absolute',
        height: '100%',
        zIndex: 2,
        userSelect: 'none'
      }}
    />
  )
}

export default React.memo(KeyboardGestureHandler)
