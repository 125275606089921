import { StyleSheet } from 'react-native'

import { gray } from '../utils/colors'

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    borderStyle: 'solid'
  },

  containerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  containerColumn: {
    flexDirection: 'column',
    alignItems: 'stretch'
  },

  item: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },

  itemRow: {
    height: '100%'
  },

  itemColumn: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch'
  },

  separator: {
    opacity: 0.1,
    backgroundColor: gray['200'].color
  },

  separatorVertical: {
    width: 1,
    height: '55%'
  },

  separatorHorizontal: {
    height: 1,
    width: '100%'
  }
})
