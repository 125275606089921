import React, { useState, useEffect, useMemo } from 'react'

import { ActivityIndicator, View } from 'react-native'

import { useMachine } from '@xstate/react'
import Machine, { Context, Event } from './machine'

import ButtonBackground from './ButtonBackground'
import ButtonIcon from './ButtonIcon'
import ButtonText from './ButtonText'
import ButtonLabel from './Label'
import { ButtonProps } from './types'
import { ignoredAction } from 'midi-city-xstate-utils'
import ButtonWrapper from './ButtonWrapper'
import styles, { color as colorDefault } from './styles'

export * from './types'

const Button = (props: ButtonProps): JSX.Element | null => {
  const {
    shouldOptimize,
    isLoading,
    onPress,
    onPressIn,
    onPressOut,
    position,
    isTouchInput,
    wrapperProps,
    disabled,
    dataSet,
    href
  } = props

  const color = props.color ?? colorDefault.toString()

  const initialContext = useMemo(() => {
    return {
      type: props.type,
      color
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [, send, interpreter] = useMachine<Context, Event>(Machine, {
    context: initialContext,
    actions: {
      onPress: onPress ?? ignoredAction,
      onPressIn: onPressIn ?? ignoredAction,
      onPressOut: onPressOut ?? ignoredAction
    }
  })

  const [isRendered, setRendered] = useState(false)

  // avoiding overhead on SSR
  useEffect(() => {
    setRendered(true)
  }, [])

  useEffect(() => {
    send({ type: 'CHANGE_TYPE', value: props.type })
  }, [send, props.type])

  useEffect(() => {
    if (disabled === true) {
      send({ type: 'DISABLE' })
    } else {
      send({ type: 'ENABLE' })
    }
  }, [disabled, send])

  useEffect(() => {
    if (isLoading === true) {
      send('LOADING_STARTED')
    } else if (isLoading === false) {
      send('LOADING_STOPPED')
    } else {
      // ignore
    }
  }, [isLoading, send])

  useEffect(() => {
    if (props.isActive === true) {
      send('EXTERNAL_ACTIVATE')
    } else if (props.isActive === false) {
      send('EXTERNAL_DEACTIVATE')
    } else {
      // ignore
    }
  }, [props.isActive, send])

  const isOnlyIcon =
    props.type !== 'custom' &&
    props.text === undefined &&
    props.SvgStart !== undefined

  if (!isRendered) {
    return null
  }

  return (
    <ButtonWrapper
      testID={props.testID}
      position={position}
      href={href}
      external={props.type === 'link' ? props.external : undefined}
      isTouchInput={isTouchInput}
      isOnlyIcon={isOnlyIcon}
      color={color}
      wrapperProps={wrapperProps}
      dataSet={dataSet}
      interpreter={interpreter}>
      <ButtonBackground
        interpreter={interpreter}
        shouldOptimize={shouldOptimize === true || false}
      />

      {isLoading === true && (
        <ActivityIndicator testID="activity-indicator" color={color} />
      )}

      {props.type !== 'custom' && isLoading !== true && (
        <>
          {props.label !== undefined && (
            <ButtonLabel text={props.label} color={color} />
          )}

          <View style={styles.nonLabelContainer}>
            {props.SvgStart !== undefined && (
              <ButtonIcon
                Svg={props.SvgStart}
                machine={interpreter}
                color={color}
                isOnlyIcon={isOnlyIcon}
                size={props.SvgStartSize}
                opacity={props.SvgStartOpacty}
                isEnd={false}
              />
            )}

            {props.text !== undefined && (
              <ButtonText
                type={props.type}
                text={props.text}
                textAlign={props.textAlign}
                color={color}
              />
            )}

            {props.SvgEnd !== undefined && (
              <ButtonIcon
                Svg={props.SvgEnd}
                size={props.SvgEndSize}
                machine={interpreter}
                color={color}
                opacity={props.SvgEndOpacty}
                isOnlyIcon={isOnlyIcon}
                isEnd={true}
              />
            )}
          </View>
        </>
      )}
      {props.type === 'custom' && props.children}
    </ButtonWrapper>
  )
}

export default React.memo(Button)
