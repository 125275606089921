import React, { memo, useState, useCallback } from 'react'
import { View, Text } from 'react-native'

import Dialog from '../containers/Dialog'
import textStyles from '../styles/text'
import { GlobalInterpreter } from 'midi-city-sound-engine'
import { SupportType } from 'midi-city-shared-types'
import { usePartial } from 'midi-city-ui'

interface PartialSupportDialogState {
  isOpen: boolean
}

interface PartialSupportDialogProps {
  global: GlobalInterpreter
}

function PartialSupportDialog({
  global
}: PartialSupportDialogProps): JSX.Element | null {
  const isSupportedPartially = usePartial(
    global,
    ({ context }) => context.supportType === SupportType.Partial
  )

  const [state, setState] = useState<PartialSupportDialogState>({
    isOpen: true
  })

  const handleClose = useCallback(() => {
    setState({ isOpen: false })
  }, [])

  if (!isSupportedPartially) {
    return null
  }

  return (
    <Dialog isOpen={state.isOpen} onClose={handleClose} onCancel={handleClose}>
      <View style={{ padding: 16 }}>
        <Text style={textStyles.header}> Warning: Unsupported Browser </Text>
        <Text style={textStyles.body2}>
          {'\n'}
          Unfortunately, your browser is not fully supported at this time.
          {'\n'}
          {'\n'}
          Please use Chrome, Safari, or Edge-Chromium to have the best
          experience possible.
          {'\n'}
          {'\n'}
          If you are using one of those browsers, please ensure you are on the
          latest version of your Operating System and have updated your browser
          application.
          {'\n'}
          {'\n'}
          You can choose to continue, but the site may not work well or at all.
          {'\n'}
          {'\n'}
          Please jump in our{' '}
          <Text
            style={textStyles.link}
            accessibilityRole="link"
            href="https://community.midi.city">
            community forum,
          </Text>{' '}
          if you think this message is invalid or you would like to see your
          current browser supported.
        </Text>
      </View>
    </Dialog>
  )
}

export default memo(PartialSupportDialog)
