import React from 'react'
import { TouchableWithoutFeedbackProps } from 'react-native'

interface NonTouchHandlerProps extends TouchableWithoutFeedbackProps {
  children: React.ReactNode
}

function NonTouchHandler({ children }: NonTouchHandlerProps): JSX.Element {
  return <>{children}</>
}

export default React.memo(NonTouchHandler)
