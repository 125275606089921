import React from 'react'
import dynamic from 'next/dynamic'
import BpmSelector from './BpmSelector'
import { Interpreter } from 'midi-city-app-manager/src/recording-manager'
import MenuButton from './MenuButton'
import { Direction } from './Menu/machine'

const Svg = dynamic(import('../images/metronome.svg'))

interface Props {
  recordingManager: Interpreter
}

function TimingButton({ recordingManager }: Props): JSX.Element {
  return (
    <MenuButton
      direction={Direction.Up}
      buttonProps={{
        wrapperProps: { style: { flex: 1 } },
        SvgStart: Svg,
        type: 'text'
      }}>
      <BpmSelector recordingManager={recordingManager} />
    </MenuButton>
  )
}

export default React.memo(TimingButton)
