import React, { useContext } from 'react'
import { assertIsObject, assertIsNumber } from 'assertate'
import dynamic from 'next/dynamic'

import Nav, { Direction } from './Nav'
import NavItem from './NavItem'
import PresetSelector from '../containers/PresetSelector'
import ZoomButtons from '../containers/ZoomButtons'
import VisualizerDynamicallyImported from './Visualizer/DynamicallyImported'

import { VirtualControllerInterpreter } from 'midi-city-app-manager/src/virtual-controller'

import { AppMachineContext } from './AppMachine'
import { depth2 } from '../utils/colors'
import { KeyboardMachineInterpreter } from 'midi-city-app-manager/src/keyboard-machine'
import { usePartial } from 'midi-city-ui'
import MenuButton from './MenuButton'
import { Direction as MenuDirection } from './Menu/machine'
import { getPresetColor } from '../utils/preset'

const Svg = dynamic(
  import(
    '../images/material-design-icons/action/svg/production/ic_settings_24px.svg'
  )
)

interface VirtualControllerNavProps {
  borderColor: string
  virtualControllerMachine: VirtualControllerInterpreter
  isTouch: boolean
}

function VirtualControllerNav(props: VirtualControllerNavProps): JSX.Element {
  const { isTouch, virtualControllerMachine } = props
  const appMachine = useContext(AppMachineContext)

  const visualizerIsEnabled = usePartial(
    virtualControllerMachine,
    ({ context }) => context.visualizerEnabled
  )

  const presetNumber = usePartial(
    virtualControllerMachine,
    ({ context }) => context.presetNumber
  )

  assertIsNumber(presetNumber)

  const bankId = usePartial(
    virtualControllerMachine,
    ({ context }) => context.bankId
  )

  assertIsNumber(bankId)

  const color = getPresetColor(presetNumber, bankId)

  const ctx = appMachine.state.context
  const { layoutManager } = ctx

  assertIsObject(layoutManager)

  const channel = virtualControllerMachine.state.context.channel

  assertIsNumber(channel)

  const layout = usePartial(
    virtualControllerMachine,
    ({ context }) => context.navLayout
  )

  assertIsObject(layout)

  return (
    <Nav
      depth={2}
      direction={Direction.Row}
      height={layout.height}
      width={layout.width}>
      <NavItem>
        <PresetSelector virtualController={virtualControllerMachine} />
      </NavItem>

      <NavItem flexGrow={1} flexShrink={1}>
        {visualizerIsEnabled && (
          <VisualizerDynamicallyImported
            nodeNum={32}
            virtualController={virtualControllerMachine}
            backgroundColor={depth2}
          />
        )}
      </NavItem>

      {!isTouch && (
        <NavItem>
          <MenuButton
            direction={MenuDirection.Left}
            buttonProps={{
              color,
              wrapperProps: { style: { flex: 1 } },
              SvgStart: Svg,
              type: 'text'
            }}>
            <ZoomButtons
              color={color}
              keyboardMachine={
                virtualControllerMachine.state.context
                  .keyboard as KeyboardMachineInterpreter
              }
            />
          </MenuButton>
        </NavItem>
      )}
    </Nav>
  )
}

export default React.memo(VirtualControllerNav)
