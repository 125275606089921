import React, {
  RefForwardingComponent,
  useRef,
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo
} from 'react'
import {
  ScrollView,
  View,
  ScrollViewProps,
  LayoutRectangle
} from 'react-native'
import { clamp } from 'lodash-es'

import ScrollViewTrackbar from './ScrollViewTrackbar'
import ScrollViewBoxShadow from './ScrollViewBoxShadow'
import styles from './scroll-view-custom-styles'

type ScrollState = number

type Ref = ScrollView | null

interface ScrollViewCustomProps extends ScrollViewProps {
  children: JSX.Element
  snapToPoints?: number[]
  isTouch: boolean
  contentSize: LayoutRectangle
  trackbarLayout: LayoutRectangle
  scrollViewDimensions: LayoutRectangle
  color?: string
  isFocused: boolean
}

const ScrollViewCustom: RefForwardingComponent<Ref, ScrollViewCustomProps> = (
  props,
  ref
): JSX.Element => {
  const {
    children,
    style,
    onScroll,
    testID,
    snapToPoints,
    isTouch,
    color,
    contentSize,
    trackbarLayout,
    scrollViewDimensions,
    contentOffset,
    isFocused
  } = props

  const [x, setX] = useState<ScrollState>(
    contentOffset !== undefined ? contentOffset.x : 0
  )

  useEffect(() => {
    if (scrollViewRef.current !== null && contentOffset !== undefined) {
      scrollViewRef.current.scrollTo({ x: contentOffset.x, animated: false })
    }
  }, [contentOffset])

  const scrollViewRef = useRef<ScrollView>(null)

  // @ts-expect-error
  useImperativeHandle(ref, (): Ref => scrollViewRef.current)

  const handleScroll = useCallback(
    (event): void => {
      const eventX = event.nativeEvent.contentOffset.x
      setX(eventX)
      onScroll?.(event)
    },
    [onScroll]
  )

  const handleTrackbarScroll = useCallback(
    (x: number): void => {
      if (scrollViewRef.current === null) {
        return
      }
      scrollViewRef.current.scrollTo({ x, animated: false })
    },
    [scrollViewRef]
  )

  const handleArrowScroll = useCallback(
    (x: number): void => {
      if (scrollViewRef.current !== null) {
        x = clamp(x, 0, contentSize.width)
        scrollViewRef.current.scrollTo({ x, animated: true })
      }
    },
    [contentSize]
  )

  const heightTotal = useMemo(
    () => trackbarLayout.height + scrollViewDimensions.height,
    [trackbarLayout, scrollViewDimensions.height]
  )

  return (
    <View
      style={[styles.container, style, { height: heightTotal }]}
      testID={testID}>
      <View
        dataSet={{ contain: 'strict' }}
        style={[
          styles.contentWrapper,
          {
            width: scrollViewDimensions.width,
            height: scrollViewDimensions.height
          }
        ]}>
        <ScrollView
          {...props}
          style={[
            props.style,
            {
              width: scrollViewDimensions.width,
              height: scrollViewDimensions.height
            }
          ]}
          ref={scrollViewRef}
          scrollEventThrottle={16}
          onScroll={handleScroll}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          dataSet={{ contain: 'strict' }}>
          {children}
        </ScrollView>
        <ScrollViewBoxShadow />
      </View>
      <ScrollViewTrackbar
        isFocused={isFocused}
        color={color}
        dataSet={{ contain: 'strict' }}
        isTouch={isTouch}
        x={x}
        snapToPoints={snapToPoints}
        onScroll={handleTrackbarScroll}
        onArrowScroll={handleArrowScroll}
        layout={trackbarLayout}
        scrollContentSize={contentSize}
      />
    </View>
  )
}

export default forwardRef(ScrollViewCustom)
