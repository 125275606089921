import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Text, StyleSheet, View } from 'react-native'
import { useService } from '@xstate/react'

import Surface from './Surface'
import stylesText from '../styles/text'
import { yellow } from '../utils/colors'
import Button from '../containers/Button'
import ButtonGroup from './ButtonGroup'
import { useSpring, animated } from 'react-spring'
import { NotificationManagerInterpreter } from 'midi-city-app-manager/src/notification-manager'
import { AppMachineContext } from './AppMachine'

const colorObject = yellow[700]
const color = colorObject.color

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderWidth: 2,
    borderColor: color
  },
  wrapper: {
    position: 'absolute',
    bottom: 16
  }
})

const stylesWrapperFlattened = StyleSheet.flatten(styles.wrapper)

const ViewAnimated = animated(View)

interface MessagesProps {
  notificationManager: NotificationManagerInterpreter
}

function Messages({ notificationManager }: MessagesProps): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)
  const [isAnimated, setIsAnimated] = useState(false)
  const [, appMachineSend] = useService(appMachine)
  const [notificationManagerState, notificationManagerSend] = useService(
    notificationManager
  )

  const message = notificationManagerState.context.notifications[0]

  const handleDismiss = useCallback(() => {
    notificationManagerSend({ type: 'CLEAR_REQUEST' })
  }, [notificationManagerSend])

  const handleAction = useCallback(() => {
    notificationManagerSend({ type: 'CLEAR_REQUEST' })
    appMachineSend(message.action)
  }, [message, appMachineSend, notificationManagerSend])

  const styleProps = useSpring({
    ...stylesWrapperFlattened,
    bottom: isAnimated ? 16 : -128
  })

  useEffect(() => {
    if (message === undefined) {
      setIsAnimated(false)
    } else {
      setIsAnimated(true)
    }
  }, [setIsAnimated, message])

  if (message === undefined) {
    return null
  }

  const { actionText, text } = message

  return (
    // @ts-expect-error
    <ViewAnimated style={styleProps}>
      <Surface depth={4} style={styles.container}>
        <Text style={[stylesText.body2]}>{text}</Text>
        <ButtonGroup color={color} style={{ justifyContent: 'flex-end' }}>
          <Button type="text" text="Dismiss" onPress={handleDismiss} />
          <Button type="text" text={actionText} onPress={handleAction} />
        </ButtonGroup>
      </Surface>
    </ViewAnimated>
  )
}

export default React.memo(Messages)
