import { cyan, teal, lightBlue, blue } from './colors'
import Svg from '*.svg'

import BassSvg from '../images/bass.svg'
import WorldSvg from '../images/world.svg'
import PianoSvg from '../images/piano.svg'
import BrassSvg from '../images/brass.svg'
import SaxSvg from '../images/sax.svg'
import FluteSvg from '../images/flute.svg'
import OrganSvg from '../images/organ.svg'
import ChromaticSvg from '../images/xylophone.svg'
import GuitarSvg from '../images/guitar.svg'
import ViolinSvg from '../images/violin.svg'
import EnsembleSvg from '../images/ensemble.svg'
import SynthLeadSvg from '../images/synth-piano.svg'
import SoundEffectsSvg from '../images/waves.svg'
import SynthPadSvg from '../images/synth-pad.svg'
import SynthEffectsSvg from '../images/rocket.svg'
import PercussiveSvg from '../images/tall-drum.svg'

export interface PresetGroup {
  title: string
  start: number
  end: number
  color: string
  Svg: typeof Svg
  presets: string[]
}

const MELODIC_PRESET_GROUPS: PresetGroup[] = [
  {
    title: 'Piano',
    start: 0,
    end: 7,
    Svg: PianoSvg,
    color: teal['300'].color,
    presets: [
      'Acoustic Grand Piano',
      'Bright Acoustic Piano',
      'Electric Grand Piano',
      'Honky-tonk Piano',
      'Electric Piano 1',
      'Electric Piano 2',
      'Harpsichord',
      'Clavinet'
    ]
  },
  {
    title: 'Chromatic Perc.',
    start: 8,
    end: 15,
    Svg: ChromaticSvg,
    color: teal['400'].color,
    presets: [
      'Celesta',
      'Glockenspiel',
      'Music Box',
      'Vibraphone',
      'Marimba',
      'Xylophone',
      'Tubular Bells',
      'Dulcimer'
    ]
  },
  {
    title: 'Organ',
    start: 16,
    end: 23,
    Svg: OrganSvg,
    color: teal['600'].color,
    presets: [
      'Drawbar Organ',
      'Percussive Organ',
      'Rock Organ',
      'Church Organ',
      'Reed Organ',
      'Accordion',
      'Harmonica',
      'Tango Accordion'
    ]
  },
  {
    title: 'Guitar',
    start: 24,
    end: 31,
    Svg: GuitarSvg,
    color: teal['700'].color,
    presets: [
      'Acoustic Guitar (nylon)',
      'Acoustic Guitar (steel)',
      'Electric Guitar (jazz)',
      'Electric Guitar (clean)',
      'Electric Guitar (muted)',
      'Overdriven Guitar',
      'Distortion Guitar',
      'Guitar Harmonics'
    ]
  },
  {
    title: 'Bass',
    start: 32,
    end: 39,
    Svg: BassSvg,
    color: cyan['700'].color,
    presets: [
      'Acoustic Bass',
      'Electric Bass (finger)',
      'Electric Bass (pick)',
      'Fretless Bass',
      'Slap Bass 1',
      'Slap Bass 2',
      'Synth Bass 1',
      'Synth Bass 2'
    ]
  },
  {
    title: 'Strings',
    start: 40,
    end: 47,
    Svg: ViolinSvg,
    color: cyan['600'].color,
    presets: [
      'Violin',
      'Viola',
      'Cello',
      'Contrabass',
      'Tremolo Strings',
      'Pizzicato Strings',
      'Orchestral Harp',
      'Timpani'
    ]
  },
  {
    title: 'Ensemble',
    start: 48,
    end: 55,
    Svg: EnsembleSvg,
    color: cyan['500'].color,
    presets: [
      'String Ensemble 1',
      'String Ensemble 2',
      'Synth Strings 1',
      'Synth Strings 2',
      'Choir Aahs',
      'Voice Oohs',
      'Synth Choir',
      'Orchestra Hit'
    ]
  },
  {
    title: 'Brass',
    start: 56,
    end: 63,
    Svg: BrassSvg,
    color: cyan['400'].color,
    presets: [
      'Trumpet',
      'Trombone',
      'Tuba',
      'Muted Trumpet',
      'French Horn',
      'Brass Section',
      'Synth Brass 1',
      'Synth Brass 2'
    ]
  },
  {
    title: 'Reed',
    start: 64,
    end: 71,
    Svg: SaxSvg,
    color: cyan['300'].color,
    presets: [
      'Soprano Sax',
      'Alto Sax',
      'Tenor Sax',
      'Baritone Sax',
      'Oboe',
      'English Horn',
      'Bassoon',
      'Clarinet'
    ]
  },
  {
    title: 'Pipe',
    start: 72,
    end: 79,
    Svg: FluteSvg,
    color: lightBlue['300'].color,
    presets: [
      'Piccolo',
      'Flute',
      'Recorder',
      'Pan Flute',
      'Blown Bottle',
      'Shakuhachi',
      'Whistle',
      'Ocarina'
    ]
  },
  {
    title: 'Synth Lead',
    start: 80,
    end: 87,
    Svg: SynthLeadSvg,
    color: lightBlue['400'].color,
    presets: [
      'Square Synth',
      'Sawtooth Synth',
      'Calliope Synth',
      'Chiff Synth',
      'Charang Synth',
      'Voice Synth',
      'Fifths Synth',
      'Bass + Lead Synth'
    ]
  },
  {
    title: 'Synth Pad',
    start: 88,
    end: 95,
    Svg: SynthPadSvg,
    color: lightBlue['500'].color,
    presets: [
      'New Age Pads',
      'Warm Pads',
      'Polysynth Pads',
      'Choir Pads',
      'Bowed Pads',
      'Metallic Pads',
      'Halo Pads',
      'Sweep Pads'
    ]
  },
  {
    title: 'Synth Effects',
    start: 96,
    end: 103,
    Svg: SynthEffectsSvg,
    color: lightBlue['600'].color,
    presets: [
      'Rain',
      'Soundtrack',
      'Crystal',
      'Atmosphere',
      'Brightness',
      'Goblins',
      'Echoes',
      'Sci-Fi'
    ]
  },
  {
    title: 'World',
    start: 104,
    end: 111,
    Svg: WorldSvg,
    color: blue['600'].color,
    presets: [
      'Sitar',
      'Banjo',
      'Shamisen',
      'Koto',
      'Kalimba',
      'Bagpipe',
      'Fiddle',
      'Shanai'
    ]
  },
  {
    title: 'Percussive',
    start: 112,
    end: 119,
    Svg: PercussiveSvg,
    color: blue['500'].color,
    presets: [
      'Tinkle Bell',
      'Agogo',
      'Steel Drum',
      'Woodblock',
      'Taiko Drum',
      'Melodic Tom',
      'Synth Drum',
      'Reverse Cymbal'
    ]
  },
  {
    title: 'Sound Effects',
    start: 120,
    end: 127,
    Svg: SoundEffectsSvg,
    color: blue['400'].color,
    presets: [
      'Guitar Fret Noise',
      'Breath Noise',
      'Seashore',
      'Bird Tweet',
      'Telephone Ring',
      'Helicopter',
      'Applause',
      'Gunshot'
    ]
  }
]

export default MELODIC_PRESET_GROUPS
