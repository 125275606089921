import React, { useCallback, useMemo } from 'react'
import Button from '../../containers/Button'
import useEventListener from 'use-typed-event-listener'

import LeftSvg from '../../images/arrow_left-24px.svg'
import RightSvg from '../../images/arrow_right-24px.svg'

export enum ScrollViewTrackbarArrowDirection {
  down,
  up
}

interface ScrollViewTrackbarArrowProps {
  direction: ScrollViewTrackbarArrowDirection
  snapToPoints?: number[]
  width: number
  onScroll: (x: number) => void
  x: number
  isTouch: boolean
  isFocused: boolean
  color?: string
}

function ScrollViewTrackbarArrow({
  direction,
  width,
  onScroll,
  x,
  snapToPoints,
  color,
  isFocused
}: ScrollViewTrackbarArrowProps): JSX.Element {
  const nextSnapToPoint = useMemo(() => {
    if (snapToPoints === undefined) {
      return
    }

    const eligiblePoints = snapToPoints.filter(point => {
      if (direction === ScrollViewTrackbarArrowDirection.down) {
        return point < x
      } else {
        return point > x
      }
    })

    if (direction === ScrollViewTrackbarArrowDirection.down) {
      return Math.max(...eligiblePoints)
    } else {
      return Math.min(...eligiblePoints)
    }
  }, [direction, snapToPoints, x])

  const handlePress = useCallback(() => {
    if (nextSnapToPoint === undefined) {
      return
    }
    onScroll(nextSnapToPoint)
  }, [nextSnapToPoint, onScroll])

  const Icon =
    direction === ScrollViewTrackbarArrowDirection.down ? LeftSvg : RightSvg

  const handleKeyDown = useCallback(
    (event): void => {
      if (!isFocused) {
        return
      }
      if (
        (event.key === 'ArrowRight' &&
          direction === ScrollViewTrackbarArrowDirection.up) ||
        (event.key === 'ArrowLeft' &&
          direction === ScrollViewTrackbarArrowDirection.down)
      ) {
        event.preventDefault()
        handlePress()
      }
    },
    [handlePress, direction, isFocused]
  )

  useEventListener(window, 'keydown', handleKeyDown)

  return (
    <Button
      color={color}
      onPress={handlePress}
      type="text"
      SvgStart={Icon}
      wrapperProps={{ style: { width, borderWidth: 0 } }}
    />
  )
}

export default React.memo(ScrollViewTrackbarArrow)
