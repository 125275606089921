import coloringPalette from 'coloring-palette'
import TinyColor from '@ctrl/tinycolor'

export const red = coloringPalette<string>('#F44336', 'hex')
export const green = coloringPalette<string>('#4caf50', 'hex')
export const teal = coloringPalette<string>('#009688', 'hex')
export const cyan = coloringPalette<string>('#00bcd4', 'hex')
export const blue = coloringPalette<string>('#2196f3', 'hex')
export const lightBlue = coloringPalette<string>('#03a9f4', 'hex')
export const indigo = coloringPalette<string>('#3949ab', 'hex')
export const gray = coloringPalette<string>('#191919', 'hex')
export const purple = coloringPalette<string>('#9C27B0', 'hex')
export const pink = coloringPalette<string>('#E91E63', 'hex')
export const yellow = coloringPalette<string>('#fdd835', 'hex')

export const shadowColor = 'rgba(0,0,0,0.12)'

export const depth0 = '#121212'

export const depth1 = TinyColor(depth0).brighten(3).toString()

export const depth2 = TinyColor(depth1).brighten(3).toString()

export const depth3 = TinyColor(depth2).brighten(3).toString()

export const depth4 = TinyColor(depth3).brighten(3).toString()
export const depth5 = TinyColor(depth4).brighten(3).toString()
export const depth6 = TinyColor(depth5).brighten(3).toString()

export const depths = new Map<number, string>([
  [0, depth0],
  [1, depth1],
  [2, depth2],
  [3, depth3],
  [4, depth4],
  [5, depth5],
  [6, depth6]
])

export const elevationShadows = new Map<number, string>([
  [1, '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)']
])

export const backgroundColor = depth0

export const globalSettingsColor = gray
export const trackControlsColor = red
