import React, { useCallback } from 'react'

import NumberPicker from './NumberPicker'
import { Interpreter } from 'midi-city-app-manager/src/recording-manager'

import { usePartial } from 'midi-city-ui'

const MAX_VALUE = 200
const MIN_VALUE = 40

interface BpmSelectorsProps {
  recordingManager: Interpreter
}

function BpmSelector({
  recordingManager
}: BpmSelectorsProps): JSX.Element | null {
  const value = usePartial(recordingManager, ({ context }) => context.bpm)

  const isRecording = false

  const handleValueChange = useCallback(
    valueNew => {
      recordingManager.send({ type: 'BPM_CHANGE_REQUEST', value: valueNew })
    },
    [recordingManager]
  )

  return (
    <NumberPicker
      disabled={isRecording}
      label="BPM"
      minValue={MIN_VALUE}
      maxValue={MAX_VALUE}
      onChange={handleValueChange}
      value={value}
    />
  )
}

export default React.memo(BpmSelector)
