import { StyleSheet } from 'react-native'

const BOX_SHADOW_WIDTH = 10

export default StyleSheet.create({
  container: {},

  trackbar: {
    flexDirection: 'row'
  },

  contentWrapper: {
    overflow: 'hidden',
    display: 'flex'
  },

  boxShadow: {
    position: 'absolute',
    top: 0,
    width: BOX_SHADOW_WIDTH,
    height: '100%',
    backgroundColor: 'transparent',
    shadowColor: '#151313',
    shadowRadius: 15
  },

  boxShadowLeft: {
    left: -BOX_SHADOW_WIDTH
  },

  boxShadowRight: {
    right: -BOX_SHADOW_WIDTH
  },

  trackbarTouch: {},

  scrubber: {
    flexDirection: 'row',
    paddingVertical: 12,
    flex: 1,
    height: '100%'
  },

  thumb: {
    borderRadius: 2
  }
})
