import React, { useCallback, useContext } from 'react'
import dynamic from 'next/dynamic'

import Button from '../containers/Button'

import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import { getInputSelected } from 'midi-city-app-manager/src/selectors/midi-device-manager'
import MidiInputDialog from './MidiInputDialog'
import { AppMachineContext } from './AppMachine'
import { usePartial } from 'midi-city-ui'

const ConnectedSvg = dynamic(
  import(
    '../images/material-design-icons/notification/svg/production/power_off-24px.svg'
  )
)

const DisconnectedSvg = dynamic(
  import(
    '../images/material-design-icons/notification/svg/production/ic_power_24px.svg'
  )
)

interface MidiInputButtonsProps {
  deviceManager: MidiDeviceManagerInterpreter
}

function MidiInputButton({
  deviceManager
}: MidiInputButtonsProps): JSX.Element {
  const appMachine = useContext(AppMachineContext)

  const inputSelected = usePartial(deviceManager, ({ context }) =>
    getInputSelected(context)
  )

  const isActive = inputSelected !== undefined

  const onOpen = useCallback(() => {
    appMachine.send({ type: 'MODAL_CHANGE', modal: 'MidiInput' })
  }, [appMachine])

  return (
    <>
      <Button
        type="text"
        label="Input"
        onPress={onOpen}
        wrapperProps={{ style: { width: '100%' } }}
        SvgStart={isActive ? DisconnectedSvg : ConnectedSvg}
      />
      <MidiInputDialog deviceManager={deviceManager} />
    </>
  )
}

export default React.memo(MidiInputButton)
