import { StyleSheet } from 'react-native'

import { shadowColor } from '../utils/colors'

export default StyleSheet.create({
  container: {
    display: 'flex',
    borderRadius: 2,
    shadowColor: shadowColor,
    shadowRadius: 3,
    shadowOffset: { width: 0, height: 1 }
  }
})
