import { Interpreter } from '.'

export function getIsActive(state: Interpreter['state']): boolean {
  return (
    state.matches({ enabled: { active: { internal: 'active' } } }) ||
    state.matches({ enabled: { active: { external: 'active' } } })
  )
}

export function getIsDisabled(state: Interpreter['state']): boolean {
  return (
    state.matches({ loading: 'active' }) ||
    state.matches({ enabled: 'inactive' })
  )
}
