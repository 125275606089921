import React, { useMemo } from 'react'
import { View, StyleSheet } from 'react-native'

import styles from './styles'
import { Interpreter, selectors } from './machine'
import { usePartial } from 'midi-city-ui'

export interface Props {
  shouldOptimize: boolean
  interpreter: Interpreter
}

const STYLE_TYPE_MAP = {
  contained: StyleSheet.flatten(styles.buttonBackgroundContained),
  outlined: StyleSheet.flatten(styles.buttonBackgroundOutlined),
  text: StyleSheet.flatten(styles.buttonBackgroundText),
  custom: StyleSheet.flatten(styles.buttonBackgroundOutlined),
  link: StyleSheet.flatten(styles.buttonBackgroundLink)
}

const OPACITY_INACTIVE_TYPE_MAP = {
  contained: 0.45,
  outlined: 0.06,
  text: 0.0,
  custom: 0.06,
  link: 0.0
}

const OPACITY_ACTIVE = 0.5

function ButtonBackground(props: Props): JSX.Element {
  const { shouldOptimize, interpreter } = props

  const color = usePartial(interpreter, ({ context }) => context.color)
  const type = usePartial(interpreter, ({ context }) => context.type)
  const isActive = usePartial(interpreter, selectors.getIsActive)

  const styleType = STYLE_TYPE_MAP[type]

  const opacityInactive = OPACITY_INACTIVE_TYPE_MAP[type]
  const opacity = isActive ? OPACITY_ACTIVE : opacityInactive

  const styleOwn = useMemo(() => {
    return { background: color }
  }, [color])

  return (
    <View
      dataSet={{ contain: 'strict', 'will-change': shouldOptimize }}
      style={[styles.buttonBackground, styleType, styleOwn, { opacity }]}
    />
  )
}

export default React.memo(ButtonBackground)
