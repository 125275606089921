import React, { ReactNode } from 'react'
import { View } from 'react-native'

import styles from '../styles/nav'
import { Direction } from './Nav'

export enum NavItemPosition {
  First,
  Middle,
  Last
}

export interface NavItemPropsProvided {
  position: NavItemPosition
  direction?: Direction
}

interface NavItemProps {
  children?: ReactNode
  flexGrow?: number
  flexShrink?: number
  position?: NavItemPosition
  direction?: Direction
  width?: number
  isActive?: boolean
}

function Separator({ direction }: { direction: Direction }): JSX.Element {
  return (
    <View
      style={[
        styles.separator,
        direction === Direction.Row
          ? styles.separatorVertical
          : styles.separatorHorizontal
      ]}
    />
  )
}

function NavItem(props: NavItemProps): JSX.Element {
  const {
    children,
    flexGrow,
    flexShrink,
    position,
    width,
    direction,
    isActive
  } = props
  const isLast = position === NavItemPosition.Last
  return (
    <>
      <View
        style={[
          styles.item,
          direction === Direction.Row ? styles.itemRow : styles.itemColumn,
          width !== undefined ? { width } : null,
          flexGrow !== undefined ? { flexGrow } : null,
          flexShrink !== undefined ? { flexShrink } : null,
          isActive === true
            ? { borderRightWidth: 4, borderRightColor: 'white' }
            : {}
        ]}>
        {children}
      </View>
      {!isLast && <Separator direction={direction as Direction} />}
    </>
  )
}

export default NavItem
