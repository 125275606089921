import React, { useMemo } from 'react'
import { Text } from 'react-native'

import styles from '../styles/text'
import { green, yellow } from '../utils/colors'

import {
  CHANNEL_NUMBER_MELODIC_DEFAULT,
  CHANNEL_NUMBER_PERCUSSION_DEFAULT
} from 'midi-city-shared-types'
import { Interpreter as MidiDeviceManagerInterpreter } from 'midi-city-app-manager/src/midi-device-manager'
import { usePartial } from 'midi-city-ui'

const WHITE_LISTED_CHANNELS = [
  CHANNEL_NUMBER_PERCUSSION_DEFAULT,
  CHANNEL_NUMBER_MELODIC_DEFAULT
]

interface MidiInputDebuggerProps {
  deviceManager: MidiDeviceManagerInterpreter
}

function MidiInputDebugger({
  deviceManager
}: MidiInputDebuggerProps): JSX.Element | null {
  const channelLatest = usePartial(
    deviceManager,
    ({ context }) => context.inputChannelLatest
  )

  const isSuccessful =
    channelLatest !== undefined && WHITE_LISTED_CHANNELS.includes(channelLatest)

  const text = `${
    channelLatest !== undefined
      ? (channelLatest as number)
      : 'no events received'
  } ${isSuccessful ? '(success)' : '(unsuccessful)'}`

  const textStyle = useMemo(() => {
    const color = isSuccessful ? green[500].color : yellow[500].color
    return [styles.body2, { color }]
  }, [isSuccessful])

  return (
    <Text style={textStyle}>
      {'\n'}
      Latest Input Channel: <Text style={{ fontWeight: '600' }}>{text}</Text>
    </Text>
  )
}

export default React.memo(MidiInputDebugger)
