import { createClient } from 'contentful'

export default function createContentfulClient(): ReturnType<
  typeof createClient
> {
  const isPreview =
    process.env.CONTENTFUL_PREVIEW === 'true' ||
    process.env.NODE_ENV !== 'production'
  const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID
  const contentfulDeliveryToken = process.env.CONTENTFUL_DELIVERY_TOKEN

  if (contentfulSpaceId === undefined) {
    throw new Error('Missing contentful space')
  }

  if (contentfulDeliveryToken === undefined) {
    throw new Error('Missing contentful access token')
  }

  return createClient({
    space: contentfulSpaceId,
    accessToken: contentfulDeliveryToken,
    host: isPreview ? 'preview.contentful.com' : undefined
  })
}
