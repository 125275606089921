import React, { useContext } from 'react'
import { View } from 'react-native'

import styles from '../styles'
import Hardware from '../containers/Hardware'
import { AppMachine } from 'midi-city-app-manager'
import { assertIsObject } from 'assertate'
import PartialSupportDialog from './PartialSupportDialog'
import { GlobalInterpreter } from 'midi-city-sound-engine'
import { usePartial } from 'midi-city-ui'
import LayoutManagerContext from './LayoutManagerContext'
import MediaBar from './MediaBar'

interface MainProps {
  appMachine: AppMachine.Interpreter
}

function Main({ appMachine }: MainProps): JSX.Element | null {
  const global = usePartial(appMachine, ({ context }) => context.global)
  const layoutManager = useContext(LayoutManagerContext)
  const layout = usePartial(layoutManager, ({ context }) => context.mainLayout)
  const page = usePartial(appMachine, ({ context }) => context.page)

  assertIsObject(layout)

  const { notificationManager } = appMachine.state.context

  assertIsObject(notificationManager)

  if (['/auth/login', '/auth/verify'].includes(page)) {
    return null
  }

  return (
    <>
      {global !== undefined && (
        <PartialSupportDialog global={global as GlobalInterpreter} />
      )}
      <View
        style={[
          styles.main,
          {
            width: layout.width,
            height: layout.height,
            left: layout.x,
            top: layout.y
          }
        ]}>
        <Hardware />
        <MediaBar />
      </View>
    </>
  )
}

export default React.memo(Main)
