import React, { useCallback, useMemo, memo } from 'react'
import { View, LayoutRectangle } from 'react-native'
import { clamp } from 'lodash-es'

import useGestures from '../../hooks/scroll-view/use-trackbar-gestures'
import styles from '../scroll-view-custom-styles'
import Surface from '../Surface'

export interface ScrollViewTrackBarScrubberProps {
  x: number
  layout: LayoutRectangle
  scrollContentSize: LayoutRectangle
  onScroll: (x: number) => void
  onScrollEndDrag?: () => void
  onScrollBeginDrag?: () => void
  snapToPoints?: number[]
}

function ScrollViewTrackbar(
  props: ScrollViewTrackBarScrubberProps
): JSX.Element {
  const {
    x,
    scrollContentSize,
    layout,
    onScroll,
    onScrollBeginDrag,
    onScrollEndDrag
  } = props

  const { width } = layout

  const visibleRatio = useMemo(() => width / scrollContentSize.width, [
    scrollContentSize,
    width
  ])

  const thumbWidth = useMemo(() => visibleRatio * width, [width, visibleRatio])

  const thumbX = useMemo(() => x * visibleRatio, [visibleRatio, x])

  const onMoveX = useCallback(
    (moveX: number): void => {
      const x = (moveX / layout.width) * scrollContentSize.width
      onScroll(clamp(x, 0, scrollContentSize.width))
    },
    [layout, scrollContentSize, onScroll]
  )

  const [panResponder, isDown] = useGestures({
    onScrollBeginDrag,
    onScrollEndDrag,
    thumbWidth,
    thumbX,
    parentLayout: layout,
    onMoveX
  })

  const cursor = isDown ? 'grabbing' : 'pointer'

  return (
    <View {...panResponder.panHandlers} style={[styles.scrubber, { cursor }]}>
      <Surface
        depth={3}
        pointerEvents="none"
        style={[
          styles.thumb,
          {
            width: thumbWidth,
            transform: [{ translateX: thumbX }]
          }
        ]}
      />
    </View>
  )
}

export default memo(ScrollViewTrackbar)
