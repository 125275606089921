import React from 'react'
import { Text } from 'react-native'
import styles from './styles'

export interface Props {
  text: string
  color?: string
}

function ButtonLabel({ text, color }: Props): JSX.Element {
  return <Text style={[styles.buttonLabel, { color }]}>{text}</Text>
}

export default React.memo(ButtonLabel)
