import React from 'react'
import { TextStyle, Text } from 'react-native'
import styles, { color as colorDefault } from './styles'
import { ButtonType } from '.'

interface ButtonTextProps {
  text: string | number
  type: ButtonType
  textAlign: TextStyle['textAlign']
  color?: string
}

function ButtonText(props: ButtonTextProps): JSX.Element {
  const { text, type, textAlign, color } = props

  return (
    <Text
      style={[
        styles.buttonText,
        type === 'link' ? styles.buttonTextLink : null,
        textAlign !== undefined ? { textAlign } : null,
        { color: color !== undefined ? color : colorDefault }
      ]}>
      {text}
    </Text>
  )
}

export default React.memo(ButtonText)
