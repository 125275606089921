import { Interpreter, EventObject, State, Actor } from 'xstate'
import { useState, useEffect } from 'react'

export function usePartial<
  TContext,
  TSchema,
  TEvent extends EventObject,
  TSelectorValue
>(
  service:
    | Interpreter<TContext, TSchema, TEvent>
    | Actor<State<TContext, TEvent>, TEvent>,
  selector: (state: State<TContext, TEvent, TSchema>) => TSelectorValue
): TSelectorValue {
  const [partialState, setPartialState] = useState(selector(service.state))

  useEffect(() => {
    const { unsubscribe } = service.subscribe(state => {
      if (state.changed !== true) return

      setPartialState(selector(state))
    })

    return (): void => unsubscribe()
  }, [selector, service])

  return partialState
}
