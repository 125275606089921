import React, { useCallback } from 'react'

import Keyboard, {
  KeyboardOwnProps,
  KeyRenderLabelProps as KeyRenderLabelPropsT
} from '../components/Keyboard'
import { AppMachine } from 'midi-city-app-manager'
import { assertIsNumber, assertIsObject } from 'assertate'
import { KeyboardMachineActor } from 'midi-city-app-manager/src/keyboard-machine'
import { usePartial } from 'midi-city-ui'

export const KeyboardContext = React.createContext(
  (undefined as unknown) as KeyboardMachineActor
)

function KeyboardContainer(props: KeyboardOwnProps): JSX.Element | null {
  const { keyboardMachine } = props

  const keyboardLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.layoutStyle
  )
  assertIsNumber(keyboardLayout)

  const dimensions = usePartial(
    keyboardMachine,
    ({ context }) => context.layout
  )
  assertIsObject(dimensions)

  const keysWrapperLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.keysWrapperLayout
  )
  assertIsObject(keysWrapperLayout)

  const keysContentLayout = usePartial(
    keyboardMachine,
    ({ context }) => context.contentLayout
  )

  assertIsObject(keysContentLayout)

  const handleScroll = useCallback(
    value => {
      keyboardMachine.send({ type: 'KEYBOARD_SCROLL_X_UPDATE', value })
    },
    [keyboardMachine]
  )

  const trackbarLayout = usePartial(
    keyboardMachine,
    AppMachine.getKeyboardTrackbarLayout
  )
  const scrollX = usePartial(keyboardMachine, AppMachine.getKeyboardScrollX)
  const scrollXInitial = usePartial(
    keyboardMachine,
    AppMachine.getKeyboardScrollXInitial
  )

  // FIXME
  const isTouch = false

  const propsProvided = {
    isTouch,
    dimensions,
    keyboardLayout,
    trackbarLayout,
    keysWrapperLayout,
    keysContentLayout,
    onScroll: handleScroll,
    scrollX,
    scrollXInitial
  }

  return (
    <KeyboardContext.Provider value={keyboardMachine}>
      <Keyboard {...props} {...propsProvided} />
    </KeyboardContext.Provider>
  )
}

export default React.memo(KeyboardContainer)
export type KeyRenderLabelProps = KeyRenderLabelPropsT
