import { useMemo } from 'react'
import {
  SectionItem,
  PickerListSectionGroups,
  Section
} from '../../components/PickerList'

export default function useSections<T>(
  groups: PickerListSectionGroups<T>
): Array<Section<T>> {
  return useMemo(
    (): Array<Section<T>> =>
      groups.map(
        (group): Section<T> => ({
          title: group.label,
          color: group.color,
          Svg: group.Svg,
          data: group.items.map(
            (item, index): SectionItem<T> => ({
              label: item.label,
              value: item.value,
              index,
              key: index
            })
          )
        })
      ),
    [groups]
  )
}
