import React, { ReactNode, useCallback, useRef, useLayoutEffect } from 'react'
import { Animated } from 'react-native'
import { Portal } from 'react-native-portalize'
import { Interpreter } from './machine'
import { usePartial } from 'midi-city-ui'

import Surface from '../Surface'
import { assertIsObject } from 'assertate'

const DEPTH = 6

interface Props {
  children: ReactNode
  interpreter: Interpreter
}

function Menu(props: Props): JSX.Element {
  const { interpreter } = props
  const parentLayout = usePartial(
    interpreter,
    ({ context }) => context.parentLayout
  )

  assertIsObject(parentLayout)

  const isOpen = usePartial(interpreter, state => state.matches('opened'))
  const opacity = useRef(new Animated.Value(0)).current

  const x = usePartial(interpreter, ({ context }) => context.offset?.x)
  const y = usePartial(interpreter, ({ context }) => context.offset?.y)

  useLayoutEffect(() => {
    Animated.spring(opacity, {
      toValue: isOpen ? 1.0 : 0,
      useNativeDriver: false
    }).start()
  }, [isOpen, opacity])

  const handleLayout = useCallback(
    event => {
      interpreter.send({
        type: 'LAYOUT_UPDATE',
        layout: event.nativeEvent.layout
      })
    },
    [interpreter]
  )

  return (
    <Portal>
      <Surface
        onLayout={handleLayout}
        style={[
          {
            padding: 16,
            zIndex: DEPTH,
            position: 'absolute',
            left: x,
            top: y
          },
          { opacity }
        ]}
        depth={DEPTH}>
        {props.children}
      </Surface>
    </Portal>
  )
}

export default React.memo(Menu)
