import React, { ReactNode } from 'react'
import { Animated, ViewStyle, ViewProps } from 'react-native'

import styles from '../styles/surface'
import { depths } from '../utils/colors'

interface SurfaceProps {
  children?: ReactNode
  depth: number
  testID?: string
  style?:
    | Animated.AnimatedProps<ViewStyle>
    | null
    | Array<Animated.AnimatedProps<ViewStyle> | null>
  pointerEvents?: ViewProps['pointerEvents']
  onLayout?: ViewProps['onLayout']
  dataSet?: ViewProps['dataSet']
}

function Surface(props: SurfaceProps): JSX.Element {
  const {
    depth,
    style,
    testID,
    children,
    pointerEvents,
    onLayout,
    dataSet
  } = props
  const backgroundColor = depths.get(depth)

  if (backgroundColor === undefined) {
    throw new Error('Missing Depth')
  }

  return (
    <Animated.View
      suppressHydrationWarning={true}
      testID={testID}
      onLayout={onLayout}
      dataSet={dataSet}
      pointerEvents={pointerEvents}
      style={[styles.container, { backgroundColor }, style]}>
      {children}
    </Animated.View>
  )
}

export default Surface
