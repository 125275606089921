import React, {
  memo,
  ReactNode,
  useCallback,
  useMemo,
  RefObject,
  useContext
} from 'react'
import {
  TouchableWithoutFeedback,
  GestureResponderEvent,
  View,
  ViewProps,
  AccessibilityRole
} from 'react-native'
import { Interpreter } from './machine'
import { usePartial } from 'midi-city-ui'
import styles from './styles'
import { ButtonGroupButtonPosition } from '../ButtonGroup'
import { getIsDisabled } from './machine/selectors'
import NonTouchHandler from './NonTouchHandler'
import { Assign } from 'utility-types'
import { AppMachineContext } from '../AppMachine'
import { AppMachine } from 'midi-city-app-manager'

export interface Props {
  interpreter: Interpreter
  href?: string
  dataSet?: ViewProps['dataSet']
  external?: boolean
  position?: ButtonGroupButtonPosition
  children: ReactNode
  isTouchInput?: boolean
  isOnlyIcon: boolean
  color?: string
  wrapperProps?: Assign<ViewProps, { ref?: RefObject<View> }>
  style?: ViewProps['style']
  testID?: string
}

const STYLE_POSITION_MAP = {
  [ButtonGroupButtonPosition.first]: styles.buttonGroupFirstChild,
  [ButtonGroupButtonPosition.center]: styles.buttonGroupCenterChild,
  [ButtonGroupButtonPosition.last]: styles.buttonGroupLastChild
}

const STYLE_TYPE_MAP = {
  contained: styles.contained,
  outlined: styles.outlined,
  custom: styles.outlined,
  text: styles.text,
  link: styles.link
}

function ButtonWrapper({
  children,
  interpreter,
  position,
  isTouchInput,
  isOnlyIcon,
  color,
  wrapperProps,
  dataSet,
  style,
  external,
  href,
  testID
}: Props): JSX.Element {
  const { send } = interpreter

  const appMachine = useContext(AppMachineContext)
  const type = usePartial(interpreter, ({ context }) => context.type)
  const disabled = usePartial(interpreter, getIsDisabled)

  const styleType = STYLE_TYPE_MAP[type]

  const stylePosition = useMemo(
    () =>
      position !== undefined
        ? [styles.buttonGroupChild, STYLE_POSITION_MAP[position]]
        : null,
    [position]
  )

  const isShowingBorder = ['custom', 'outlined'].includes(type)

  const styleCombined = useMemo(
    () => [
      styles.container,
      isTouchInput === true ? styles.containerTouch : null,
      isOnlyIcon ? styles.containerWithOnlyIcon : null,
      styleType,
      stylePosition,
      isShowingBorder ? { borderColor: color } : null,
      wrapperProps?.style,
      style,
      disabled ? styles.containerDisabled : null
    ],
    [
      isOnlyIcon,
      color,
      style,
      stylePosition,
      styleType,
      isTouchInput,
      disabled,
      isShowingBorder,
      wrapperProps
    ]
  )

  const handlePress = useCallback(
    (event: GestureResponderEvent): void => {
      if (type === 'link' && external !== true) {
        event?.preventDefault()
        if (appMachine.state.context.page !== href) {
          appMachine.send({
            type: 'PAGE_CHANGE',
            page: href as AppMachine.Page
          })
        }
      }
      send({ type: 'PRESS' })
    },
    [send, external, type, appMachine, href]
  )

  const handlePressIn = useCallback(
    (_event: GestureResponderEvent): void => {
      send({ type: 'PRESS_IN' })
    },
    [send]
  )

  const handlePressOut = useCallback(
    (_event: GestureResponderEvent): void => {
      send({ type: 'PRESS_OUT' })
    },
    [send]
  )

  const Handler =
    type === 'custom' || wrapperProps?.href !== undefined
      ? NonTouchHandler
      : TouchableWithoutFeedback

  const linkProps =
    type === 'link'
      ? { accessibilityRole: 'link' as AccessibilityRole, href }
      : {}

  return (
    <Handler
      onPressOut={handlePressOut}
      onPressIn={handlePressIn}
      onPress={handlePress}>
      <View
        testID={testID}
        {...linkProps}
        {...wrapperProps}
        style={styleCombined}
        dataSet={{ 'web-user-select-none': true, ...(dataSet ?? {}) }}>
        {children}
      </View>
    </Handler>
  )
}

export default memo(ButtonWrapper)
