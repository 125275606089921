import React, { useCallback, useMemo, memo } from 'react'
import { Preset } from 'midi-city-api'

import Picker from '../containers/PickerCustom'
import { GroupItem, Group } from './PickerList'
import MELODIC_PRESET_GROUPS from '../utils/melodic-preset-groups'

import Svg from '*.svg'
import {
  BankIdT,
  BANK_ID_MELODIC_DEFAULT,
  ChannelT,
  PresetNumber
} from 'midi-city-shared-types'
import { VirtualControllerInterpreter } from 'midi-city-app-manager/src/virtual-controller'

interface ChangeTrackPresetIdArgs {
  bankId: BankIdT
  presetNumber: PresetNumber
}

export interface PresetSelectorPropsProvided {
  channel: ChannelT
  presets: Preset[]
  presetNumber: PresetNumber
  bankId: BankIdT
  changePreset: (arg: ChangeTrackPresetIdArgs) => void
  color: string
  Svg: typeof Svg
  disabled: boolean
}

export interface PresetSelectorPropsOwn {
  virtualController: VirtualControllerInterpreter
}

export interface PresetSelectorProps
  extends PresetSelectorPropsOwn,
    PresetSelectorPropsProvided {}

function getPresetsGroupItems(
  presetNames: string[],
  groupIndex: number
): Array<GroupItem<PresetNumber>> {
  return presetNames.map(
    (name, presetIndex): GroupItem<PresetNumber> => ({
      label: name,
      value: (groupIndex * 8 + presetIndex) as PresetNumber
    })
  )
}

function PresetSelector(props: PresetSelectorProps): JSX.Element {
  const {
    presetNumber,
    bankId,
    changePreset,
    color,
    Svg,
    presets,
    disabled,
    channel
  } = props

  const groups = useMemo(
    () =>
      bankId === BANK_ID_MELODIC_DEFAULT
        ? MELODIC_PRESET_GROUPS.map(
            ({ title, color, Svg, presets }, index): Group<PresetNumber> => {
              return {
                label: title,
                Svg,
                color,
                items: getPresetsGroupItems(presets, index)
              }
            }
          )
        : [
            {
              label: 'Drums',
              items: presets
                .filter(preset => preset.bankId === bankId)
                .map(preset => ({
                  label: preset.name,
                  value: preset.midiId
                }))
            }
          ],
    [bankId, presets]
  )

  const handleValueChange = useCallback(
    (presetNumberNew: PresetNumber): void => {
      changePreset({ bankId, presetNumber: presetNumberNew })
    },
    [changePreset, bankId]
  )

  const testID = useMemo(() => {
    return `preset-selector-channel-${channel.toString()}`
  }, [channel])

  return (
    <Picker
      testID={testID}
      disabled={disabled}
      useStepper={true}
      color={color}
      Svg={Svg}
      onSelect={handleValueChange}
      dialogTitle="Change Preset"
      selectedValue={presetNumber}
      groups={groups}
    />
  )
}

export default memo(PresetSelector)
