import React, { useCallback, memo } from 'react'

import Button from '../containers/Button'
import { gray } from '../utils/colors'
import CheckedSvg from '../images/material-design-icons/toggle/svg/production/ic_radio_button_checked_48px.svg'
import UncheckedSvg from '../images/material-design-icons/toggle/svg/production/ic_radio_button_unchecked_48px.svg'

interface Props<T> {
  value: T
  label: string
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  onPress(value: T): void
  isSelected: boolean
  color: string
}

function PickerCustomItem<T>(props: Props<T>): JSX.Element {
  const { label, value, onPress, isSelected, color } = props

  const handlePress = useCallback((): void => {
    onPress(value)
  }, [value, onPress])

  const Icon = isSelected ? CheckedSvg : UncheckedSvg

  const iconColor = isSelected ? color : gray['600'].color

  return (
    <Button
      color={iconColor}
      type="text"
      onPress={handlePress}
      SvgStart={Icon}
      text={label}
    />
  )
}

export default memo(PickerCustomItem)
