import React, { useContext, useCallback, useMemo } from 'react'
import { nanoid } from 'nanoid/non-secure'

import PickerCustom, { PickerCustomOwnProps } from '../components/PickerCustom'
import { LayoutManagerContext, usePartial } from 'midi-city-ui'

import { AppMachineContext } from '../../'

function PickerCustomContainer<T>(props: PickerCustomOwnProps<T>): JSX.Element {
  const layoutManager = useContext(LayoutManagerContext)
  const appMachine = useContext(AppMachineContext)

  const modal = useMemo(() => {
    return props.modal ?? nanoid()
  }, [props.modal])

  const isOpen = usePartial(
    appMachine,
    ({ context }) => context.modalOpen === modal
  )

  const isCompact = usePartial(
    layoutManager,
    ({ context }) => context.isNarrowWidth
  )

  const handleOpen = useCallback(() => {
    appMachine.send({ type: 'MODAL_CHANGE', modal })
  }, [appMachine, modal])

  const handleClose = useCallback(() => {
    appMachine.send({ type: 'MODAL_CHANGE', modal: undefined })
  }, [appMachine])

  return (
    <PickerCustom
      {...props}
      modal={modal}
      isCompact={isCompact}
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}

export default React.memo(PickerCustomContainer)
