import React, { useCallback, useState, memo } from 'react'
import dynamic from 'next/dynamic'
import { ViewProps, View } from 'react-native'
import Svg from '*.svg'
import { Assign } from 'utility-types'

import PickerList, { Group } from './PickerList'
import { useFlatItems, useSelectedGroupItem } from '../hooks/picker-list'
import Button from '../containers/Button'
import ButtonGroup from './ButtonGroup'
import PickerTriggerMain from './PickerTriggerMain'
import Dialog from '../containers/Dialog'
import { AppMachine } from 'midi-city-app-manager'

const UpSvg = dynamic(
  import(
    '../images/material-design-icons/navigation/svg/production/ic_arrow_upward_24px.svg'
  ),
  {
    ssr: false
  }
)

const DownSvg = dynamic(
  import(
    '../images/material-design-icons/navigation/svg/production/ic_arrow_downward_24px.svg'
  ),
  {
    ssr: false
  }
)

export interface PickerCustomOwnProps<T> extends ViewProps {
  selectedValue: T
  groups: Array<Group<T>>
  dialogTitle: string
  color?: string
  useStepper?: boolean
  disabled?: boolean
  modal?: AppMachine.SingletonModal
  Svg?: typeof Svg
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  onSelect(value: T): void
  testID?: string
}

export interface PickerCustomProvidedProps {
  isOpen: boolean
  isCompact?: boolean
  onOpen: () => void
  onClose: () => void
  modal: string
}

export type PickerCustomProps<T> = Assign<
  PickerCustomOwnProps<T>,
  PickerCustomProvidedProps
>

interface State<T> {
  selectedValueOriginal: T
}

function PickerCustom<T>({
  selectedValue,
  isOpen,
  groups,
  onSelect,
  dialogTitle,
  color,
  Svg,
  style,
  onOpen,
  onClose,
  testID,
  isCompact = false,
  useStepper = false,
  disabled = false
}: PickerCustomProps<T>): JSX.Element {
  const [state, setState] = useState<State<T>>({
    selectedValueOriginal: selectedValue
  })

  const items = useFlatItems(groups)
  const selectedGroupItem = useSelectedGroupItem(selectedValue, groups)

  const handleDecrement = useCallback((): void => {
    if (selectedGroupItem !== undefined) {
      let prevIndex = items.indexOf(selectedGroupItem) - 1
      if (prevIndex < 0) {
        prevIndex = items.length - 1
      }
      const prevItem = items[prevIndex]
      const prevValue = prevItem.value
      onSelect(prevValue)
    }
  }, [items, selectedGroupItem, onSelect])

  const handleIncrement = useCallback((): void => {
    if (selectedGroupItem !== undefined) {
      let nextIndex = items.indexOf(selectedGroupItem) + 1
      if (nextIndex >= items.length - 1) {
        nextIndex = 0
      }
      const nextItem = items[nextIndex]
      const nextValue = nextItem.value
      onSelect(nextValue)
    }
  }, [items, selectedGroupItem, onSelect])

  const handleClose = useCallback((): void => {
    onClose()
    setState(
      (state): State<T> => ({
        ...state,
        selectedValueOriginal: selectedValue
      })
    )
  }, [selectedValue, onClose])

  const handleCancel = useCallback((): void => {
    onSelect(state.selectedValueOriginal)
    onClose()
  }, [onSelect, onClose, state.selectedValueOriginal])

  const width = groups.length > 1 ? 800 : undefined
  const height = groups.length > 1 ? 700 : undefined

  const ButtonWrapper = useStepper ? ButtonGroup : View

  return (
    <>
      <ButtonWrapper color={color} style={style} testID={testID}>
        <PickerTriggerMain
          disabled={disabled}
          color={color}
          Svg={Svg}
          groups={groups}
          isCompact={isCompact}
          onPress={onOpen}
          selectedValue={selectedValue}
        />
        {useStepper && (
          <View>
            <Button
              disabled={disabled}
              onPress={handleDecrement}
              SvgStart={UpSvg}
              color={color}
              wrapperProps={{ style: { minHeight: 'auto', flex: 1 } }}
              SvgStartSize={16}
              SvgStartOpacty={0.5}
              type="text"
              testID={testID !== undefined ? `${testID}-stepper-up` : undefined}
            />
            <Button
              disabled={disabled}
              color={color}
              onPress={handleIncrement}
              SvgStart={DownSvg}
              SvgStartSize={16}
              SvgStartOpacty={0.5}
              wrapperProps={{ style: { minHeight: 'auto', flex: 1 } }}
              type="text"
              testID={
                testID !== undefined ? `${testID}-stepper-down` : undefined
              }
            />
          </View>
        )}
      </ButtonWrapper>
      {isOpen && (
        <Dialog
          width={width}
          height={height}
          isOpen={true}
          onClose={handleClose}
          onCancel={handleCancel}
          title={dialogTitle}>
          <PickerList
            isNarrowWidth={isCompact}
            groups={groups}
            onSelect={onSelect}
            selectedValue={selectedValue}
          />
        </Dialog>
      )}
    </>
  )
}

export default memo(PickerCustom)
