import moize from 'moize'
import { assertIsObject } from 'assertate'
import {
  BankIdT,
  PresetNumber,
  BANK_ID_PERCUSSION_DEFAULT
} from 'midi-city-shared-types'
import Svg from '*.svg'

import { purple } from './colors'

import SvgBase from 'midi-city-ui/src/images/bass.svg'
import DrumIcon from 'midi-city-ui/src/images/snare.svg'

import MELODIC_PRESET_GROUPS, { PresetGroup } from './melodic-preset-groups'

export const getPresetMelodicPresetGroup = moize(
  (presetNumber: PresetNumber): PresetGroup => {
    const presetGroup = MELODIC_PRESET_GROUPS.find(
      (group): boolean =>
        presetNumber >= group.start && presetNumber <= group.end
    )
    assertIsObject(presetGroup)
    return presetGroup
  }
)

export function getPresetColor(
  presetNumber: PresetNumber,
  bankId: BankIdT
): string {
  const isMelodic = bankId !== BANK_ID_PERCUSSION_DEFAULT

  if (!isMelodic) {
    return purple['500'].color
  }
  const presetGroup = getPresetMelodicPresetGroup(presetNumber)
  return presetGroup !== null ? presetGroup.color : 'white'
}

export function getPresetIcon(
  presetNumber: PresetNumber,
  bankId: BankIdT
): typeof Svg {
  const isMelodic = bankId !== BANK_ID_PERCUSSION_DEFAULT
  if (!isMelodic) {
    return DrumIcon
  }
  const presetGroup = getPresetMelodicPresetGroup(presetNumber)
  return presetGroup !== null ? presetGroup.Svg : SvgBase
}
