import React, { memo, useMemo } from 'react'
import { LayoutRectangle, ViewProps } from 'react-native'

import styles from './scroll-view-custom-styles'
import Scrubber from './ScrollViewTrackbar/Scrubber'
import Arrow, {
  ScrollViewTrackbarArrowDirection
} from './ScrollViewTrackbar/Arrow'
import Surface from './Surface'

interface ScrollViewTrackBarProps {
  x: number
  layout: LayoutRectangle
  scrollContentSize: LayoutRectangle
  onScroll: (x: number) => void
  onArrowScroll: (x: number) => void
  onScrollEndDrag?: () => void
  onScrollBeginDrag?: () => void
  snapToPoints?: number[]
  isTouch: boolean
  color?: string
  isFocused: boolean
  dataSet: ViewProps['dataSet']
}

const ARROW_WIDTH = 48

function ScrollViewTrackbar(props: ScrollViewTrackBarProps): JSX.Element {
  const {
    x,
    layout,
    snapToPoints,
    onArrowScroll,
    isTouch,
    color,
    isFocused
  } = props

  const scrubberLayout = useMemo(
    () => ({ ...layout, width: layout.width - ARROW_WIDTH * 2 }),
    [layout]
  )

  return (
    <Surface
      depth={2}
      style={[
        styles.trackbar,
        { height: layout.height, width: layout.width },
        isTouch ? styles.trackbarTouch : null
      ]}
      dataSet={{ 'use-inset-padding-bottom': isTouch }}>
      <Arrow
        isFocused={isFocused}
        color={color}
        x={x}
        isTouch={isTouch}
        snapToPoints={snapToPoints}
        onScroll={onArrowScroll}
        width={ARROW_WIDTH}
        direction={ScrollViewTrackbarArrowDirection.down}
      />
      <Scrubber {...props} layout={scrubberLayout} />
      <Arrow
        isFocused={isFocused}
        color={color}
        x={x}
        isTouch={isTouch}
        snapToPoints={snapToPoints}
        onScroll={onArrowScroll}
        width={ARROW_WIDTH}
        direction={ScrollViewTrackbarArrowDirection.up}
      />
    </Surface>
  )
}

export default memo(ScrollViewTrackbar)
