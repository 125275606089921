import React, { ComponentType } from 'react'
import Svg from '*.svg'
import { Interpreter } from './machine'
import { color as colorDefault } from './styles'

export interface ButtonIconProps {
  Svg: typeof Svg | ComponentType<React.SVGProps<SVGSVGElement>>
  size?: number
  color?: string
  opacity?: number
  isOnlyIcon: boolean
  machine: Interpreter
  isEnd: boolean
}

function ButtonIcon(props: ButtonIconProps): JSX.Element {
  const { Svg, opacity, color, isOnlyIcon, isEnd } = props
  const size = props.size ?? 24
  return (
    <Svg
      fill={color ?? colorDefault}
      width={size}
      height={size}
      style={{
        flexGrow: isOnlyIcon ? 1 : 0,
        opacity: opacity ?? 1.0,
        justifySelf: isEnd ? 'flex-end' : 'flex-start'
      }}
    />
  )
}

export default React.memo(ButtonIcon)
