import React, {
  useContext,
  useCallback,
  ReactNode,
  useRef,
  useEffect,
  useMemo
} from 'react'
import { nanoid } from 'nanoid/non-secure'

import Button, { ButtonProps } from '../Button'
import { AppMachineContext } from '../AppMachine'
import { usePartial } from 'midi-city-ui'
import { View } from 'react-native'
import machine, { Direction } from '../Menu/machine'
import Menu from '../Menu'

import { useMachine } from '@xstate/react'
import LayoutManagerContext from '../LayoutManagerContext'

interface Props {
  buttonProps: ButtonProps
  children: ReactNode
  direction: Direction
}

function MenuButton({ buttonProps, children, direction }: Props): JSX.Element {
  const id = useMemo(() => {
    return nanoid()
  }, [])

  const appMachine = useContext(AppMachineContext)
  const layoutManager = useContext(LayoutManagerContext)

  const [state, send, interpreter] = useMachine(machine, {
    context: { direction }
  })
  const windowLayout = usePartial(
    layoutManager,
    ({ context }) => context.windowLayout
  )

  const ref = useRef<View>(null)

  const isOpen = usePartial(
    appMachine,
    ({ context }) => context.menuOpen === id
  )

  const handlePress = useCallback(() => {
    appMachine.send({
      type: 'MENU_CHANGE',
      menu: isOpen ? undefined : id
    })
  }, [id, appMachine, isOpen])

  useEffect(() => {
    if (state.value === 'initializing') {
      return
    }
    if (isOpen) {
      send('OPEN')
    } else {
      send('CLOSE')
    }
  }, [isOpen, state.value, send])

  const handleLayout = useCallback(() => {
    ref.current?.measureInWindow((x, y, width, height) => {
      send({ type: 'PARENT_LAYOUT_UPDATE', layout: { x, y, width, height } })
    })
  }, [send])

  useEffect(() => {
    handleLayout()
  }, [handleLayout, windowLayout])

  return (
    <>
      {isOpen && interpreter !== undefined && (
        <Menu interpreter={interpreter}>{children}</Menu>
      )}
      <Button
        {...buttonProps}
        onPress={handlePress}
        wrapperProps={{
          ...buttonProps.wrapperProps,
          onLayout: handleLayout,
          ref
        }}
      />
    </>
  )
}

export default React.memo(MenuButton)
