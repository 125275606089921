import { InMemoryCache, ApolloClient, createHttpLink } from '@apollo/client'
import { assert, assertIsString } from 'assertate'
import { setContext } from '@apollo/client/link/context'

const SANITY_GRAPHQL_URL = process.env.SANITY_GRAPHQL_URL
const SANITY_API_TOKEN = process.env.SANITY_API_TOKEN
const isProd = process.env.NODE_ENV === 'production'

if (isProd && SANITY_API_TOKEN !== undefined) {
  throw new Error('SANITY_API_TOKEN leaked into prod')
}

assertIsString(SANITY_GRAPHQL_URL)
assert(SANITY_GRAPHQL_URL.length > 0)

const link = createHttpLink({
  uri: SANITY_GRAPHQL_URL
})

const authLink = setContext((_, { headers }) => {
  if (SANITY_API_TOKEN === undefined) {
    return { headers }
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${SANITY_API_TOKEN}`
    }
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

export default apolloClient
