import React, { ReactNode, useContext } from 'react'
import { Text } from 'react-native'
import { usePartial } from '../hooks/xstate-partial'
import { AppMachineContext } from './AppMachine'
import BlockContent from '@sanity/block-content-to-react'

import stylesText from '../styles/text'

const linkSerializer = (props: {
  children: ReactNode
  href: string
  mark: { href: string }
}): JSX.Element => {
  return (
    <Text
      style={stylesText.link}
      href={props.mark.href}
      accessibilityRole="link">
      {props.children}
    </Text>
  )
}

const normalSerializer = (props: { children: ReactNode }): JSX.Element => {
  return (
    <Text style={[stylesText.body2, { margin: '1em' }]}>
      {' '}
      {props.children}{' '}
    </Text>
  )
}

function CopyrightNotice(): JSX.Element | null {
  const appMachine = useContext(AppMachineContext)
  const globalSettings = usePartial(
    appMachine,
    ({ context }) => context.globalSettings?.allGlobalSettings[0]
  )

  if (globalSettings === undefined) {
    return null
  }

  const { copyrightNoticeRaw } = globalSettings

  return (
    <BlockContent
      blocks={copyrightNoticeRaw}
      serializers={{
        types: { block: normalSerializer },
        marks: { link: linkSerializer }
      }}
    />
  )
}

export default React.memo(CopyrightNotice)
