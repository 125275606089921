import imageUrlBuilder from '@sanity/image-url'
import { assertIsString } from 'assertate'

const SANITY_ASSET_CDN_HOST = process.env.SANITY_ASSET_CDN_HOST
assertIsString(SANITY_ASSET_CDN_HOST)

const projectId = process.env.SANITY_PROJECT_ID
assertIsString(projectId)

const builder = imageUrlBuilder({
  projectId,
  dataset: 'production',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  baseUrl: `https://${SANITY_ASSET_CDN_HOST}`
})
export default builder
