import { StyleSheet } from 'react-native'
import { backgroundColor } from './utils/colors'

export default StyleSheet.create({
  page: {
    backgroundColor: backgroundColor,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  main: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
  },

  picker: {
    margin: 8,
    backgroundColor: backgroundColor,
    color: 'white'
  }
})
