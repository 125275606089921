import React from 'react'
import styles from '../styles'
import stylesText from '../styles/text'
import { View, Text } from 'react-native'

export default function UnsupportedBrowser(): JSX.Element {
  return (
    <View style={[styles.page, { width: '100%' }]}>
      <View style={[styles.main]}>
        <Text style={[stylesText.header, { padding: 32 }]}>
          Error: Unsupported Browser
          {'\n'}
          {'\n'}
          Please use Chrome, Safari, or Edge-Chromium to have the best
          experience possible.
          {'\n'}
          {'\n'}
          Firefox and Opera are partially supported as well.
          {'\n'}
        </Text>
      </View>
    </View>
  )
}
