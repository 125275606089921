import { Context as MidiDeviceManagerContext } from '../midi-device-manager'
import { KeyboardMachineActor } from '../keyboard-machine'
import { assertIsObject } from 'assertate'
import { Input as MidiInput } from 'webmidi'

export function getKeyboards(
  ctx: MidiDeviceManagerContext
): KeyboardMachineActor[] {
  const { virtualControllers } = ctx
  assertIsObject(virtualControllers)
  return Array.from(virtualControllers.values()).map(
    virtualController => virtualController.state.context.keyboard
  )
}

export function getInputSelected(
  ctx: MidiDeviceManagerContext
): MidiInput | undefined {
  const { midiInputs, midiInputSelectedId } = ctx
  if (midiInputSelectedId === undefined) {
    return undefined
  }

  return midiInputs.find(({ id }) => id === midiInputSelectedId)
}
