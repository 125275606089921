import React, { ReactNode, cloneElement } from 'react'
import flattenChildren from 'react-keyed-flatten-children'

import styles from '../styles/nav'
import Surface from './Surface'
import { NavItemPosition } from './NavItem'

export enum Direction {
  Row,
  Column
}

interface NavProps {
  children: ReactNode
  height: number
  width?: number
  depth: number
  direction: Direction
}

function Nav(props: NavProps): JSX.Element {
  const { children, height, depth, width, direction } = props
  const childrenFlattened = flattenChildren(children)
  const childrenCount = childrenFlattened.length

  return (
    <Surface
      depth={depth}
      style={[
        styles.container,
        direction === Direction.Row
          ? styles.containerRow
          : styles.containerColumn,
        { height },
        width !== undefined ? { width } : null
      ]}>
      {childrenFlattened.map((child, index) => {
        if (child === null) {
          return null
        }
        const isFirst = index === 0
        const isLast = index === childrenCount - 1

        let position

        if (isFirst) {
          position = NavItemPosition.First
        } else if (isLast) {
          position = NavItemPosition.Last
        } else {
          position = NavItemPosition.Middle
        }
        // @ts-expect-error
        return cloneElement(child, { position, direction })
      })}
    </Surface>
  )
}

export default React.memo(Nav)
